import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-tech-external-analysis',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class TechExternalAnalysisComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Tech and external analysis involves gathering expert opinions, market data, and research to identify market drivers and trends, such as technological advancements and legislative developments. This process covers gathering expert opinion, market data and market research. Tracking market drivers and trends such as technology & legislation developments. Translating these into opportunities & threats.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product VP",
    "description": "Approves the analysis and ensures strategic alignment with organizational goals."
  },
  {
    "name": "Product Manager",
    "description": "Leads the research process, coordinates input from various stakeholders, and translates insights into actionable plans."
  },
  {
    "name": "Architect",
    "description": "Provides technical expertise to assess the impact of technology trends and advancements on product direction."
  },
  {
    "name": "CTO",
    "description": "Oversees technology alignment with organizational goals and ensures that technology-related insights are incorporated into planning."
  },
  {
    "name": "Growth",
    "description": "Tracks market trends and identifies growth opportunities based on external drivers."
  },
  {
    "name": "Strategy (CSO)",
    "description": "Ensures the findings are integrated into the broader business strategy and opportunity assessment."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Identifying Market Drivers and Trends",
            "description": {
              "Objective": [
                "Track and analyze market drivers, such as technological advancements and legislative changes, to identify potential opportunities and threats."
              ],
              "Activity": [
                "Use tools like PESTLE analysis to assess the impact of political, economic, social, technological, legal, and environmental factors.",
                "Conduct Mega Trends research to identify overarching patterns and their implications for the product."
              ],
              "Outcome": [
                "A comprehensive understanding of external market drivers and trends."
              ]
            }
          },
          {
            "title": "Expert Opinion Gathering",
            "description": {
              "Objective": [
                "Leverage expertise across domains to validate findings and identify implications for the product."
              ],
              "Activity": [
                "Engage with industry experts, consultants, and analysts for insights.",
                "Collaborate with architects and the CTO to assess technological opportunities and threats."
              ],
              "Outcome": [
                "Validated insights with actionable recommendations."
              ]
            }
          },
          {
            "title": "Opportunity and Threat Assessment",
            "description": {
              "Objective": [
                "Translate trends and insights into strategic opportunities or threats for the product."
              ],
              "Activity": [
                "Summarize findings using techniques like Facts & Implications analysis.",
                "Create a risk-reward matrix to prioritize actions based on findings."
              ],
              "Outcome": [
                "Actionable list of opportunities and threats that inform strategic decisions."
              ]
            }
          }
        ]
      }
    },    
    {
      type: 'superListItem',
      value: {
        title: 'Practical Examples',
        items: [
          {
            title: 'Legislation Impact Assessment', description:
            {
              'Objective': ['Analyzing new data privacy laws to identify compliance requirements and opportunities for differentiation.'],
              'Activity': [],
              'Outcome': []
            }
          },
          {
            title: 'Technology Trend Analysis', description:
            {
              'Objective': ['Researching emerging AI technologies to assess their applicability to enhance product features.'],
              'Activity': [],
              'Outcome': []
            }
          }
        ]
      }
    },
    {
      type:'checkListItem',
      value:{
        title:'Tools / Resources / Templates',
        items:[
          {id:1, text:'PESTLE Analysis Template: Structured template for conducting comprehensive market analysis.'},
          {id:2, text:'Mega Trends Research Report: Example report for identifying key trends.'},
          {id:3, text:'Facts & Implications Worksheet: Template to summarize findings and translate them into actions.'}
        ]
      }
    },
  ];
}