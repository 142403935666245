import { Component } from '@angular/core';

@Component({
  selector: 'pet-requirements',
  template: 
  `
  <p>
      Home > ADAPT Lifecycle Stages > Roadmap & Planning > Requirements
  </p>
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class RequirementsComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'The Requirements process is essential for defining, refining, and prioritizing what needs to be delivered to meet strategic and customer needs. This process is continuous, starting from high-level quarterly planning and breaking down into detailed requirements for backlog refinement. It ensures alignment with the overarching roadmap and strategic goals, providing a clear foundation for the development teams. Requirements are shaped by insights gathered during the Discovery & Validation stage, where roles like the Product Manager, Product Designer, and Tech Lead collaborate with customers to explore and validate ideas.'
      }
    },
    {
      type:'listItem',
      value:{
        title:'Roles and Responsibilities',
        items:[
          {name:'Product Director', description:'Oversees strategic alignment and provides input on high-level goals. Provide input on strategic priorities and business value, ensuring that the roadmap aligns with overall organizational objectives and customer needs. Their feedback helps shape and adjust requirements as necessary.'},
          {name:'Product Manager', description:'Leads the requirements gathering and roadmap planning process, ensuring that strategic goals are clearly articulated and aligned with market needs. The Product Manager works closely with stakeholders to prioritize features and epics that deliver the highest value.'},
          {name:'Product Manager/Owner*', description:'Collaborates with the Product Manager to refine and prioritize requirements, ensuring they are broken down into manageable epics, features, and user stories. The Product Owner also ensures that the requirements are ready for development in upcoming sprints.'},
          {name:'Tech Lead', description:'Provides technical insights and ensures feasibility, identifying potential technical challenges or dependencies that could impact the roadmap. The Tech Lead also helps in breaking down larger items into more actionable tasks and offers technical guidance.'},
          {name:'Product Designer', description:'Engages early in the planning process to contribute design considerations and ensure the requirements are user-centric. They help to validate concepts through prototypes and user feedback, contributing to a better-defined product vision.'}
        ]
      }
    },
    {
      type:'checkListItem',
      value:{
        title:'Prerequisites',
        items:[
          {id:1, text:'Feedback from the Discovery & Validation process with validated concepts.'},
          {id:2, text:'Initial high-level roadmap outlining strategic themes and objectives.'},
          {id:3, text:'Stakeholder alignment and approval of the overall product vision.'}
        ]
      }
    },
    {
      type: 'superListItem',
      value: {
        title: 'Process Steps',
        items: [
          {
            title: 'Linking with Discovery',
            description: {
              'Objective': [
                'Ensure requirements are grounded in validated ideas from the Discovery & Validation stage.'
              ],
              'Activity': [
                'Refine requirements to align with the product vision, roadmap, and strategy.',
                'Collaborate with stakeholders to ensure clarity, feasibility, and alignment with business objectives.'
              ],
              'Outcome': [
                'Clear, actionable requirements that bridge discovery insights with product strategy.'
              ]
            }
          },
          {
            title: 'Breaking Down Requirements',
            description: {
              'Objective': [
                'Decompose high-level ideas into structured, actionable components.'
              ],
              'Activity': [
                'Epics: Define high-level features or initiatives aligned with strategic goals.',
                'Features: Outline functionalities that support epics and can be delivered within a few sprints.',
                'User Stories: Create the smallest units of work, structured with clear acceptance criteria.'
              ],
              'Outcome': [
                'A hierarchy of requirements that ensures traceability from strategic goals to deliverable tasks.'
              ]
            }
          },
          {
            title: 'Writing User Stories',
            description: {
              'Objective': [
                'Develop user stories focused on customer value and actionable outcomes.'
              ],
              'Activity': [
                'Craft user stories with clear and structured Acceptance Criteria.',
                'Ensure stories are testable and linked to measurable business objectives.',
                'Refer to the "Information & Support" section for detailed guidelines on writing effective user stories.'
              ],
              'Outcome': [
                'Well-defined user stories that are ready for implementation and aligned with customer needs.'
              ]
            }
          }
        ]
      }
    },    
{type:'listItem',
  value:{
    title:'Practical Examples',
    items:[
      {name:'Example 1:', description:'An epic related to a new feature may be broken down into several features, each with its own user stories. Acceptance criteria ensure all requirements are testable.'},
      {name:'Example 2:', description:'User story: “As a user, I want to receive a confirmation email after registration so that I can be sure my registration was successful.” <br>Acceptance Criteria: Email is sent to the registered user’s email address, contains registration details, and is delivered within 2 minutes.'}
      
    ]
  }
},
{
  type:'checkListItem',
  value:{
    title:'Tools / Resources / Templates',
    items:[
      {id:1, text:'Azure DevOps for managing epics, features, and user stories.'},
      {id:2, text:'Templates for writing user stories and acceptance criteria.'},
      {id:3, text:'Access to market analysis reports for validating requirements.'}
    ]
  }
},
];
}
