import { Component } from '@angular/core';

@Component({
  selector: 'pet-adapt-lifecycle',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class AdaptLifecycleComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'The ADAPT framework organizes product development into six interconnected lifecycle stages. Each stage plays a crucial role in guiding teams from ideation to scaling products in the market. Together, these stages form a cohesive roadmap for achieving strategic objectives and delivering value.'
      }
    },   
    {
      type: 'superListItem',
      value: {
        title: 'The Six Lifecycle Stages of ADAPT',
        items: [
          {
            title: 'Strategy & Vision', description:
            {
              'Objective': ['Define the long-term direction for the product portfolio.'],
              'Activity': ['Strategy Cascade',
                'Portfolio Planning',
                'Investment Alignment'],
              'Outcome': ['Strategic alignment across all teams and initiatives.']
            }
          },
          {
            title: 'Discovery & Validation', description:
            {
              'Objective': ['Identify and validate opportunities that address customer pain points.'],
              'Activity': ['Market Analysis',
                'Customer Research',
                'Continuous Discovery Cycles'],
              'Outcome': ['Validated problem statements and solution hypotheses.']
            }
          },
          {
            title: 'Roadmap & Planning', description:
            {
              'Objective': ['Translate validated opportunities into actionable roadmaps.'],
              'Activity': ['Prioritization',
                'Backlog Refinement',
                'Stakeholder Alignment'],
              'Outcome': ['Realistic, well-prioritized roadmaps.']
            }
          },
          {
            title: 'Development & Product Readiness', description:
            {
              'Objective': ['Build and test products to ensure they meet quality standards.'],
              'Activity': ['Sprint Planning & Execution',
                'Coding & Testing',
                'Readiness Signoff'],
              'Outcome': ['Deployable, production-ready products.']
            }
          },
          {
            title: 'Release & Operational Readiness:', description:
            {
              'Objective': ['Ensure smooth deployment and operational handoffs.'],
              'Activity': ['Deployment Planning',
                'Training',
                'Operational Readiness Checks'],
              'Outcome': ['Reliable product launches with minimal disruptions.']
            }
          },
          {
            title: 'Go-to-Market & Scaling', description:
            {
              'Objective': ['Maximize the product’s impact in the market.'],
              'Activity': ['Partner Engagement',
                'Sales Enablement',
                'Market Scaling Strategies'],
              'Outcome': ['Successful market adoption and growth']
            }
          }
        ]
      }
    },
    {
      type: 'intro',
      value: {
        mainText: 'Why These Stages Matter',
        subText: 'These stages provide a clear roadmap for teams, ensuring that every activity contributes to the larger strategic goals. By breaking down the product lifecycle into these manageable stages, ADAPT simplifies complex processes while maintaining flexibility.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Quick Comparison with Other Frameworks',
        items: [
          { name: 'SAFe', description: 'Top heavy, emphasizes large-scale planning but lacks flexibility at the team level.' },
          { name: 'LeSS', description: ' Focuses on scaling Agile practices but doesn’t address end-to-end lifecycle coverage.' },
          { name: 'ADAPT', description: 'Combines flexibility and structure, ensuring both strategic alignment and team autonomy.' },
        ]
      }
    },        
];
}
