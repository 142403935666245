import { Component } from '@angular/core';

@Component({
  selector: 'pet-rad-matrix',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class RadMatrixComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'What is RAD?',
        subText:'The RAD Matrix is a streamlined approach to defining roles and responsibilities in a fast-paced, SaaS-driven environment. Unlike traditional models like RACI, RAD eliminates unnecessary layers of complexity, focusing instead on clarity and action. In a world where agility, speed, and efficiency are critical, RAD ensures responsibilities are clear, approvals are decisive, and deliverables are well-supported.'
      }
    },     
    {
      "type": "multibulletList",
      "value": {
        "title": "Key Advantages of the RAD Matrix",
        "items": [
          {
            "name": "Clarity in Ownership",
            "description": [
              "RAD reduces the clutter of multiple 'responsible' or 'accountable' parties.",
              "There is no ambiguity about who is driving the result or who makes the decision."
            ]
          },
          {
            "name": "Action-Oriented Framework",
            "description": [
              "Unlike RACI, which includes 'consulted' and 'informed' roles that can dilute focus, RAD narrows the scope to ensure the team remains decisively action-oriented."
            ]
          },
          {
            "name": "Efficiency in SaaS Environments",
            "description": [
              "SaaS companies thrive on speed and iteration.",
              "RAD aligns with this dynamic by eliminating redundant roles and empowering teams to act decisively."
            ]
          },
          {
            "name": "Transparency as a Byproduct",
            "description": [
              "In modern SaaS environments, transparency is achieved through tools, dashboards, and collaborative platforms.",
              "RAD assumes that information flows organically to all stakeholders, making 'consulted' and 'informed' designations obsolete."
            ]
          },
          {
            "name": "Lean and Scalable",
            "description": [
              "RAD adapts to the fluid nature of Empowered Product Teams.",
              "Whether you’re scaling up or managing cross-functional responsibilities, RAD ensures efficiency without compromising clarity."
            ]
          }
        ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Implementing RAD Matrix",
        "items": [
          {
            "title": "Define Responsibilities",
            "description": {
              "Objective": [
                "Clearly identify the individual(s) responsible for ensuring the task or objective is completed."
              ],
              "Activity": [
                "Assign ownership of the task with clear expectations and deliverables.",
                "Use collaborative tools to track responsibilities transparently."
              ],
              "Outcome": [
                "A single point of accountability for driving tasks forward."
              ]
            }
          },
          {
            "title": "Set Approvals",
            "description": {
              "Objective": [
                "Designate approvers with veto power to maintain alignment with strategic goals."
              ],
              "Activity": [
                "Identify individuals or groups with authority to approve or reject decisions.",
                "Ensure approval timelines are aligned with project goals."
              ],
              "Outcome": [
                "Efficient decision-making with minimal bottlenecks."
              ]
            }
          },
          {
            "title": "Enable Deliverables",
            "description": {
              "Objective": [
                "Ensure contributors provide timely information and resources."
              ],
              "Activity": [
                "Collaborate with subject matter experts to gather relevant data and insights.",
                "Leverage tools for seamless information sharing and collaboration."
              ],
              "Outcome": [
                "High-quality inputs enabling successful execution by the responsible party."
              ]
            }
          }
        ]
      }
    }    
];
}
