
import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-product-plan',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ProductPlanComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'ProductPlan is a cloud-based roadmap software designed to help teams communicate their strategy, prioritize tasks, and share plans visually. It offers tools to create and customize roadmaps, collaborate with stakeholders, and ensure alignment across teams, making it an essential tool for modern product management.'
      }
    },
    {
      type: 'hyperlink',
      value: {
        heading: 'KDI Instances', // Section heading
        links: [
          {
            title: 'KDI ProductPlan Instance',
            url: 'https://app.productplan.com/'
          }
        ]
      }
    }
     
  ];
}