import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-testing',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class TestingComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Testing is an integral component of the ADAPT Framework, ensuring early detection of issues and delivering high-quality software. The Testing process is built around automation-first principles, early collaboration, and exploratory testing for creative defect discovery. It ensures continuous quality through CI/CD integration and empowers teams to deliver robust, high-quality software efficiently. By integrating testing throughout the sprint lifecycle, the process promotes collaboration, continuous feedback, and agility, aligning with modern DevOps and Agile principles.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "QA Lead",
    "description": "Oversees the entire testing process, ensures alignment with sprint goals, and prioritizes testing tasks."
  },
  {
    "name": "Development Team",
    "description": "Supports QA with unit and integration tests, provides fixes for defects, and collaborates during exploratory testing."
  },
  {
    "name": "Scrum Master",
    "description": "Facilitates collaboration between QA and development, ensuring adequate time is allocated for testing."
  },
  {
    "name": "Product Owner",
    "description": "Defines acceptance criteria, ensures that testable user stories are well-specified, and approves final results."
  },
  {
    "name": "Automation Engineer",
    "description": "Develops and maintains automated testing frameworks and scripts to ensure continuous validation."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Early QA Involvement",
            "description": {
              "Objective": [
                "Integrate QA from the start of the sprint lifecycle to ensure alignment and readiness for testing."
              ],
              "Activity": [
                "Include QA in sprint planning and backlog refinement.",
                "Collaborate on defining acceptance criteria and identifying testable conditions.",
                "Prepare test plans and automation scripts early in the sprint."
              ],
              "Outcome": [
                "Clear test scope and alignment with development goals from the beginning."
              ]
            }
          },
          {
            "title": "Automation-Driven Testing",
            "description": {
              "Objective": [
                "Prioritize automation for repetitive, regression, and API testing to maximize coverage and efficiency."
              ],
              "Activity": [
                "Develop and maintain automated test scripts using frameworks like Selenium, Cypress, or Playwright.",
                "Integrate automated tests into the CI/CD pipeline for continuous validation.",
                "Automate API testing with tools like Postman or Rest Assured."
              ],
              "Outcome": [
                "High efficiency in testing repetitive scenarios and faster feedback loops for developers."
              ]
            }
          },
          {
            "title": "Exploratory Manual Testing",
            "description": {
              "Objective": [
                "Leverage manual testing for creative exploration and identifying non-obvious defects."
              ],
              "Activity": [
                "Focus manual testing on exploratory testing rather than scripted scenarios.",
                "Use exploratory techniques to validate edge cases and usability issues.",
                "Document findings and report defects with detailed context."
              ],
              "Outcome": [
                "Enhanced defect discovery through creative, human-led testing."
              ]
            }
          },
          {
            "title": "Continuous Testing Throughout the Sprint",
            "description": {
              "Objective": [
                "Implement a culture of continuous testing to identify issues as early as possible."
              ],
              "Activity": [
                "Start testing as soon as a workable feature or code is available.",
                "Perform incremental validation and provide immediate feedback to developers.",
                "Use shift-left practices to identify defects early in the development cycle."
              ],
              "Outcome": [
                "Faster defect resolution and improved development quality."
              ]
            }
          },
          {
            "title": "Test Collaboration and Feedback",
            "description": {
              "Objective": [
                "Foster collaboration between QA, developers, and stakeholders to address issues swiftly."
              ],
              "Activity": [
                "Conduct daily standups and mid-sprint reviews to sync testing efforts.",
                "Share defect trends and test coverage insights with the team.",
                "Encourage developers to support testing when tasks are completed."
              ],
              "Outcome": [
                "Stronger teamwork and quicker resolution of defects."
              ]
            }
          }
        ]
      }
    },
    {type:'attachment',
      value:{
        title:'Media/Attachments',
        items:[
          {src:'assets/media/images/ADAPT-TestingFlow.png', description:'Testing Process with focus on overall quality'}
        ]
      }
    }, 
    {
      type:'listItem',
      value:{
        title:'Artifacts and Deliverables',
        items:[
          {title:'Test Plans', description: 'Comprehensive test strategy for the sprint, including automation scripts and exploratory testing approaches.'
          },
          {title:'Test Results', description: 'Reports from automated and manual testing highlighting defect trends, coverage, and quality metrics, preferably integrated in the Azure DevOps CI/CD pipeline.'
          },
          {title:'Bug Reports', description: 'Traceably categorized records of identified bugs, their triage status (priority/severity), and resolution status.'
          },
          {title:'Automation Frameworks', description: 'Updated automation scripts integrated with the CI/CD pipeline.'
          },
          {title:'Test Coverage Reports', description: 'Documentation of areas tested, providing visibility into quality assurance efforts, preferably reflected on the Insights Portal.'
          }
        ]
      }
    }
  ];
}