import { Component } from '@angular/core';

@Component({
  selector: 'pet-product-manager',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ProductManagerComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Role Definition',
        subText:'A Product Manager in KDI owns the product strategy, decides the product roadmap and manages the Product Backlog. It is an outward-facing role, in that they speak to customers to define the requirements of the product to be built. The Product Manager may represent the needs of many stakeholders in the Product Backlog. Those wanting to change the Product Backlog can do so by trying to convince the Product Manager.'
      }
    },     
    {
      type: 'checkListItem',
      value: {
        title: 'Prerequisites',
        items: [
          { id: 1, text: 'Authority and ownership for Product Strategy, Product Roadmap and Product Backlog.' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Deliverables',
        items: [
          { id: 1, text: 'Product Strategy document' },
          { id: 2, text: 'Product Business Cases' },
          { id: 3, text: 'Product Roadmap with Epics' },
          { id: 4, text: 'Prioritized and ordered Product Backlog' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Responsibilities',
        items: [
          { id: 1, text: 'Must talk regularly to relevant stakeholders like Customers, Product Leaders, Product Owners, Product Marketing, Delivery, etc., to stay aligned and informed.' },
          { id: 2, text: 'Developing a multiyear product strategy with roadmap management.' },
          { id: 3, text: 'Manage and ensure that the product backlog is Prioritized and ordered.' },
          { id: 4, text: 'Ensuring that the Product Backlog is transparent, visible, understood, and done according to the overall product roadmap.' },
          { id: 5, text: 'Defining value of the product.' },
          { id: 6, text: 'Communicating the product strategy with all departments and at all levels so it is well understood.' },
          { id: 7, text: 'Must talk regularly to relevant stakeholders like Customers, Product Marketing, Customer Success, Delivery, Growth etc.' }
        ]
      }
    }, 
];
}
