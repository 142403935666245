import { Component } from '@angular/core';

@Component({
  selector: 'pet-version-history',
  template: `
    <div class="page-title-container">
      <div class="status-tag-container">
        <span [ngClass]="statusClass" class="status-tag">{{ status }}</span>
      </div>
    </div>
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: [`
    .page-title-container {
      position: relative; /* Position the container relative for overlay */
    }
    .status-tag-container {
      position: absolute; /* Place the tag container relative to the title */
      top: -10px; /* Adjust the distance above the title */
      left: 0; /* Align with the title horizontally */
    }
    .status-tag {
      padding: 0.2rem 0.5rem;
      font-size: 0.85rem;
      font-weight: 500;
      border-radius: 12px;
      color: #fff;
    }
    .badge-draft {
      background-color: #007bff; /* Bootstrap primary blue */
    }
    .badge-published {
      background-color: #28a745; /* Bootstrap success green */
    }
  `]
})

export class VersionHistoryComponent {
  public status: string = 'Draft'; // Change to 'Published' when the version history is ready
  public statusClass: string = 'badge-draft'; // Dynamically set based on status

  public pageData: any = [
    {
      type: 'intro',
      value: {
        mainText: 'Version History',
        subText: 'This section will provide a detailed log of changes, updates, and enhancements made to the ADAPT framework over time. The Version History helps maintain transparency and ensures that all stakeholders are informed about the evolution of processes and tools. We are in the process of compiling this information. Please stay tuned for updates.'
      }
    }
  ];
}
