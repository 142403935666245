import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-faqs',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class FaqsComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Have questions? We’ve got answers! Our Frequently Asked Questions (FAQs) section is designed to address common queries about tools, frameworks, processes, and certifications. Whether you\'re new to the platform or looking for advanced guidance, you’ll find the information you need here.'
      }
    },
    {
      type: 'faq',
      value: {
        heading: 'Frequently Asked Questions',
        questions: [
          {
            question: 'What is the ADAPT Framework?',
            answer: 'The ADAPT Framework is a lifecycle framework designed to ensure alignment, clarity, and delivery across all stages of product development.'
          },
          {
            question: 'What tools are part of the ADAPT ecosystem?',
            answer: 'Key tools include Azure DevOps, SonarQube, Mend, ProductPlan, and custom tools for roadmap management and KPI tracking.'
          },
          {
            question: 'How do I access the tools required for my role?',
            answer: 'Access can be requested via IT support or the onboarding portal. Certain tools may require manager approval.'
          },
          {
            question: 'Can the ADAPT Framework be customized for specific teams?',
            answer: 'Yes, ADAPT is flexible and can be tailored to meet the unique needs of different teams while maintaining core principles.'
          },
          {
            question: 'How often should I update my roadmap in ProductPlan?',
            answer: 'Roadmaps should be updated at least once per sprint or whenever significant changes occur.'
          },
          {
            question: 'What is the sprint planning process in ADAPT?',
            answer: 'Sprint planning involves setting clear goals, defining tasks, and ensuring alignment with the product roadmap and team capacity.'
          },
          {
            question: 'How do we track progress during sprints?',
            answer: 'Use tools like Azure DevOps to monitor sprint burndown, work item progress, and team velocity.'
          },
          {
            question: 'What is the RAD matrix?',
            answer: 'The RAD matrix (Responsible, Approval, Deliverable) defines the roles and responsibilities for each activity within the ADAPT lifecycle.'
          },
          {
            question: 'How do I integrate ProductPlan with Azure DevOps?',
            answer: 'ProductPlan offers integrations with Azure DevOps to synchronize work items and ensure roadmap alignment with task execution.'
          },
          {
            question: 'How does Mend help with open-source compliance?',
            answer: 'Mend scans your codebase for vulnerabilities and licensing issues in open-source dependencies, providing actionable recommendations.'
          },
          {
            question: 'What are the key features of SonarQube?',
            answer: 'SonarQube provides static code analysis, vulnerability detection, technical debt estimation, and coding standard enforcement.'
          },
          {
            question: 'How do I set up CI/CD pipelines in Azure DevOps?',
            answer: 'Navigate to the Pipelines section in Azure DevOps, define a pipeline configuration (YAML or Classic), and integrate it with your repository and build tools.'
          },
          {
            question: 'Are there training modules for ProductPlan or SonarQube?',
            answer: 'Yes, training modules for ProductPlan, SonarQube, and other tools are available in the Training Resources section.'
          },
          {
            question: 'Who do I contact if I face issues with a tool?',
            answer: 'For technical issues, contact IT support. For process-related questions, reach out to your manager or the ADAPT support team.'
          },
          {
            question: 'How do I provide feedback about the ADAPT Framework?',
            answer: 'Use the feedback form available on the ADAPT portal or share it directly with your manager.'
          },
          {
            question: 'What is the process for suggesting changes to ADAPT?',
            answer: 'Submit your suggestions through the Change Management process outlined in the Product Forum setting. All requests are reviewed by the Product Forum steering committee.'
          },
          {
            question: 'What is technical debt, and how does SonarQube help reduce it?',
            answer: 'Technical debt refers to the implied cost of additional rework caused by suboptimal coding practices. SonarQube quantifies and identifies these issues, providing guidance to resolve them.'
          },
          {
            question: 'Can ProductPlan generate reports for stakeholders?',
            answer: 'Yes, ProductPlan provides reporting and visualization features that can be customized for stakeholder presentations.'
          }
        ]
      }
    }
     
  ];
}