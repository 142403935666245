import { Component } from '@angular/core';

@Component({
  selector: 'pet-sprint-review-retro',
  template: 
  `
  <p>
      Home > ADAPT Lifecycle Stages > Development & Product Readiness > Sprint Review & Retrospection
  </p>
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class SprintReviewRetroComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'Sprint Review & Retrospection are two essential ceremonies that wrap up each sprint. The Sprint Review focuses on demonstrating completed work to stakeholders and gathering feedback, while the Sprint Retrospective is a team-centered session to reflect on the sprint’s performance and identify opportunities for improvement. These activities ensure continuous learning, product alignment, and process enhancements.'
      }
    },
    {
      type:'listItem',
      value:{
        title:'Roles and Responsibilities',
        items:[
          {name:'Product Manager/Owner*', description:'Reviews the completed work, gathers stakeholder feedback, and discusses product vision.'},
          {name:'Scrum Master', description:'Facilitates both ceremonies, ensuring productive discussions and adherence to Agile practices.'},
          {name:'Development Team', description:'Demonstrates completed work during the review and actively participates in retrospection to improve team processes.'},
          {name:'Key Stakeholders', description:'Attend the Sprint Review to provide feedback and align on product goals.'}
        ]
      }
    },
    {
      type:'checkListItem',
      value:{
        title:'Prerequisites',
        items:[
          {id:1, text:'A completed sprint with deliverables ready for demonstration.'},
          {id:2, text:'A well-documented list of sprint outcomes and metrics to review.'},
          {id:3, text:'An open mindset from the team to discuss successes and areas for improvement.'}
        ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Sprint Review",
            "description": {
              "Objective": [
                "Present completed work to stakeholders, gather feedback, and assess progress against roadmap goals."
              ],
              "Frequency": [
                "End of every sprint."
              ],
              "Activity": [
                "Demo of Completed Work: The development team showcases features, bug fixes, and improvements that meet the Definition of Done.",
                "Feedback Gathering: Stakeholders share their thoughts on the presented work and discuss any potential changes or new requirements.",
                "Roadmap Alignment Discussion: The Product Owner highlights how completed work aligns with the roadmap and any upcoming priorities."
              ],
              "Outcome": [
                "Validated deliverables, stakeholder feedback for future backlog refinement, and alignment on next steps."
              ]
            }
          },
          {
            "title": "Sprint Retrospective",
            "description": {
              "Objective": [
                "Reflect on the sprint to identify what went well, what didn’t, and how the team can improve."
              ],
              "Frequency": [
                "End of every sprint, immediately following the Sprint Review."
              ],
              "Activity": [
                "Celebrate Successes: The team discusses positive aspects of the sprint and acknowledges achievements.",
                "Identify Challenges: Reflect on issues or obstacles faced and brainstorm solutions.",
                "Action Plan Creation: Define specific, actionable steps to improve team processes and avoid repeating past mistakes."
              ],
              "Outcome": [
                "A set of actionable improvement items and a stronger team commitment to continuous improvement."
              ]
            }
          }
        ]
      }
    },
{type:'listItem',
  value:{
    title:'Practical Examples',
    items:[
      {name:'Example 1:', description:'During the Sprint Review, a stakeholder suggests a small enhancement to a feature. The Product Owner captures this feedback for future backlog prioritization.'},
      {name:'Example 2:', description:'In the Sprint Retrospective, the team realizes they faced a recurring issue with communication and decides to introduce daily cross-functional syncs to address this.'}
      
    ]
  }
},
{
  type:'checkListItem',
  value:{
    title:'Tools / Resources / Templates',
    items:[
      {id:1, text:'Azure DevOps for running retrospection activity, and consolidating retrospective items.'},
      {id:2, text:'Azure DevOps Feedback Capture Tool: A board for real-time stakeholder feedback collection during the Sprint Review.'}
    ]
  }
},
];
}
