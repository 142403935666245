@if(summaryMenu){
<div id="mySidebar" class="sidebar" style="height: 75%; overflow-y: auto;"
    [ngStyle]="{'background': theme === 'light' ? '#f5f5f5' : '#1e1e2e', 'border-color': theme === 'light' ? '#ddd' : '#333247'}">
    <h5 class="opacity-75">
        <ng-container *ngIf="summaryMenu?.icon">
            <i [ngClass]="[summaryMenu.icon, 'fs-1', 'm-2']"></i>
        </ng-container>
        {{ summaryMenu?.heading }}
    </h5>
    <p class="opacity-75" [innerHTML]="sanitizedDescription"></p>


    <!-- Preparation section (only shown if infoLevel is 1) - RENAMED TO RELATED -->
    <ng-container *ngIf="summaryMenu?.infoLevel === 1">
        <p class="opacity-75"><br><strong>Related:</strong></p>
        <ul class="opacity-75">
            <li *ngFor="let item of summaryMenu?.preparation;">
                <strong>{{ item?.title }}</strong> {{ item?.content }}
            </li>
        </ul>
        <p class="opacity-75">{{ summaryMenu?.additionalInfo }}</p>
    </ng-container>

    <!-- Outcomes and Techniques section (only shown if infoLevel is 2) -->
    <ng-container *ngIf="summaryMenu?.infoLevel === 2">
        <h5 class="mt-10 opacity-75"><strong>Outcomes & Techniques:</strong></h5>
        <div class="container outcome-box mt-2"
            [ngStyle]="{'background': theme === 'light' ? '#f5f5f5' : '#1e1e2e', 'border-color': theme === 'light' ? '#ddd' : '#333247'}">
            <div class="row border p-3">
                <!-- Part 1: Navigation Tabs for Outcomes -->
                <div class="col-12 col-md-6">
                    <ul class="nav nav-pills flex-column mt-5 fs-6" id="outcomeTabs" role="tablist">
                        <li class="nav-item" *ngFor="let item of summaryMenu?.outcomes; let i = index">
                            <span class="nav-link outcome-tabs opacity-75"
                                [ngClass]="{ active: selectedOutcomeIndex === i, '': selectedOutcomeIndex === i }"
                                (click)="selectOutcome(i)" id="outcomeTab{{i}}" data-bs-toggle="pill"
                                [attr.href]="'#outcomeContent' + i" role="tab"
                                [attr.aria-controls]="'outcomeContent' + i"
                                [attr.aria-selected]="selectedOutcomeIndex === i" [ngbPopover]="item.Outcome"
                                triggers="mouseenter:mouseleave"
                                [ngStyle]="{ 'background-color':selectedOutcomeIndex === i ? '#01273c' : '', 'cursor': 'pointer' }">
                                {{ item.Outcome }}
                            </span>
                        </li>
                    </ul>
                </div>

                <!-- Part 2: Outcome Details -->
                <div class="col-12 col-md-6 mt-5">
                    <div class="tab-content" id="outcomeTabContent">
                        <div *ngFor="let item of summaryMenu?.outcomes; let i = index" class="tab-pane fade"
                            [ngClass]="{ show: selectedOutcomeIndex === i, active: selectedOutcomeIndex === i }"
                            id="outcomeContent{{i}}" role="tabpanel" [attr.aria-labelledby]="'outcomeTab' + i">


                            <ul class="nav nav-pills mb-5" id="innerTabs" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link opacity-75" [ngClass]="{ active: selectedTab === 'techniques' }"
                                        (click)="selectTab('techniques')" id="techniquesTab" data-bs-toggle="pill"
                                        role="tab" aria-controls="techniquesContent"
                                        aria-selected="selectedTab === 'techniques'"
                                        [ngStyle]="{ 'background-color': selectedTab === 'techniques' ? '#013f62' : '', 'cursor': 'pointer' }">
                                        Techniques
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link opacity-75" [ngClass]="{ active: selectedTab === 'processes' }"
                                        (click)="selectTab('processes')" id="processesTab" data-bs-toggle="pill"
                                        role="tab" aria-controls="processesContent"
                                        aria-selected="selectedTab === 'processes'"
                                        [ngStyle]="{ 'background-color': selectedTab === 'processes' ? '#013f62' : '', 'cursor': 'pointer' }">
                                        Processes
                                    </a>
                                </li>
                            </ul>
                            <hr class="mt-1">
                            <div class="tab-content mb-5">
                                <!-- Techniques Content -->
                                <div class="tab-pane fade" id="techniquesContent" role="tabpanel"
                                    [ngClass]="{ show: selectedTab === 'techniques', active: selectedTab === 'techniques' }">
                                    <div class="">
                                        <div class="">
                                            <div *ngFor="let technique of item.Techniques" class="mt-2 d-inline-block">
                                                <div class="btn outcome-btn fs-8 p-2 rounded-xl m-1 opacity-75">
                                                    {{ technique }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Processes Content -->
                                <div class="tab-pane fade" id="processesContent" role="tabpanel"
                                    [ngClass]="{ show: selectedTab === 'processes', active: selectedTab === 'processes' }">
                                    <div class="">
                                        <div class="">
                                            <div *ngFor="let process of item.Processes" class="mt-2 d-inline-block">
                                                <div class="btn outcome-btn fs-8 p-2 rounded-xl m-1 opacity-75">
                                                    {{ process }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-10">
            <h5 class="mb-4 "><strong class="opacity-75">RAD </strong>
                <button class="btn btn-icon btn-icon-primary btn-active-color-primary justify-content-start"
                    data-kt-menu-trigger="hover" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true"
                    [ngbPopover]="radInfoText" triggers="mouseenter:mouseleave">
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen045.svg'"
                        class="svg-icon svg-icon-2x svg-icon-info"></span>
                </button>
            </h5>
            <!-- Rectangular Box containing section titles -->
            <div class="rectangular-box mb-4 d-flex flex-wrap">
                <div class="title-box flex-fill  p-2 opacity-75">Responsible</div>
                <div class="title-box flex-fill  p-2 opacity-75">Approver</div>
                <div class="title-box flex-fill  p-2 opacity-75">Deliver</div>
            </div>

            <!-- Circle Container Rows -->
            <div class="row mt-8">

                <!-- Responsible Section -->
                <div class="col-12 col-md-4 circle-div opacity-75">
                    <div class="circle-container">
                        <ng-container *ngFor="let responsible of summaryMenu?.RAD?.Responsible; let i = index">
                            <div *ngIf="i < 2" class="circle" [ngStyle]="{'background-color': getIndexColors(i)}"
                                [ngbPopover]="responsible" triggers="mouseenter:mouseleave">
                                {{ getFirstTwoLetters(responsible) }}
                            </div>
                            <div *ngIf="showAllResponsibles && i >= 2" class="circle"
                                [ngStyle]="{'background-color': getIndexColors(i)}" [ngbPopover]="responsible"
                                triggers="mouseenter:mouseleave">
                                {{ getFirstTwoLetters(responsible) }}
                            </div>
                            <div *ngIf="i === summaryMenu?.RAD?.Responsible.length - 1  && i >= 2" class="circle muted"
                                (click)="toggleResponsibleVisibility()">
                                <ng-container *ngIf="!showAllResponsibles">
                                    +{{ summaryMenu?.RAD?.Responsible.length - 2 }}
                                </ng-container>
                                <ng-container *ngIf="showAllResponsibles">-</ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- Approver Section -->
                <div class="col-12 col-md-4 circle-div">
                    <div class="circle-container">
                        <ng-container *ngFor="let approver of summaryMenu?.RAD?.Approver; let i = index">
                            <div *ngIf="i < 2" class="circle" [ngStyle]="{'background-color': getIndexColors(i)}"
                                [ngbPopover]="approver" triggers="mouseenter:mouseleave">
                                {{ getFirstTwoLetters(approver) }}
                            </div>
                            <div *ngIf="showAllApprovers && i >= 2" class="circle"
                                [ngStyle]="{'background-color': getIndexColors(i)}" [ngbPopover]="approver"
                                triggers="mouseenter:mouseleave">
                                {{ getFirstTwoLetters(approver) }}
                            </div>
                            <div *ngIf="i === summaryMenu?.RAD?.Approver.length - 1 && i >= 2" class="circle muted"
                                (click)="toggleApproverVisibility()">
                                <ng-container *ngIf="!showAllApprovers">
                                    +{{ summaryMenu?.RAD?.Approver.length - 2 }}
                                </ng-container>
                                <ng-container *ngIf="showAllApprovers">-</ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>



                <!-- Deliver Section -->
                <div class="col-12 col-md-4 circle-div">
                    <div class="circle-container">
                        <ng-container *ngFor="let deliver of summaryMenu?.RAD?.Deliver; let i = index">
                            <div *ngIf="i < 2" class="circle" [ngStyle]="{'background-color': getIndexColors(i)}"
                                [ngbPopover]="deliver" triggers="mouseenter:mouseleave">
                                {{ getFirstTwoLetters(deliver) }}
                            </div>
                            <div *ngIf="showAllDelivers && i >= 2" class="circle"
                                [ngStyle]="{'background-color': getIndexColors(i)}" [ngbPopover]="deliver"
                                triggers="mouseenter:mouseleave">
                                {{ getFirstTwoLetters(deliver) }}
                            </div>

                            <div *ngIf="i === summaryMenu?.RAD?.Deliver.length - 1  && i >= 2" class="circle muted"
                                (click)="toggleDeliverVisibility()">
                                <ng-container *ngIf="!showAllDelivers">
                                    +{{ summaryMenu?.RAD?.Deliver.length - 2 }}
                                </ng-container>
                                <ng-container *ngIf="showAllDelivers">-</ng-container>
                            </div>

                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="p-4 mt-10 addition-content" *ngIf="summaryMenu?.attachment"
        [ngStyle]="{'background': theme === 'light' ? '#fafafa' : '#1e1e2e', 'border-color': theme === 'light' ? '#ddd' : '#333247'}">
        <div class="d-flex align-items-center">
            <i class="fa-solid fa-paperclip fs-3 me-2"></i>
            <span class="fs-4"> <strong class="opacity-75">Attachment</strong></span>
        </div>
        <div class="tip-section mt-4">
            <ul class="list-unstyled">
            <li class="opacity-65 mb-2" *ngFor="let attachment of summaryMenu?.attachment">
                <i [ngClass]="{
                    'fa-file-pdf': attachment.source.endsWith('.pdf'),
                    'fa-file-word': attachment.source.endsWith('.doc') || attachment.source.endsWith('.docx'),
                    'fa-file-excel': attachment.source.endsWith('.xls') || attachment.source.endsWith('.xlsx'),
                    'fa-file-powerpoint': attachment.source.endsWith('.ppt') || attachment.source.endsWith('.pptx'),
                    'fa-file-image': attachment.source.endsWith('.jpg') || attachment.source.endsWith('.jpeg') || attachment.source.endsWith('.png'),
                    'fa-file-video': attachment.source.endsWith('.mp4') || attachment.source.endsWith('.avi'),
                    'fa-file-audio': attachment.source.endsWith('.mp3') || attachment.source.endsWith('.wav'),
                    'fa-file-archive': attachment.source.endsWith('.zip') || attachment.source.endsWith('.rar'),
                    'fa-file-alt': !attachment.source.endsWith('.pdf') && !attachment.source.endsWith('.doc') && !attachment.source.endsWith('.docx') && !attachment.source.endsWith('.xls') && !attachment.source.endsWith('.xlsx') && !attachment.source.endsWith('.ppt') && !attachment.source.endsWith('.pptx') && !attachment.source.endsWith('.jpg') && !attachment.source.endsWith('.jpeg') && !attachment.source.endsWith('.png') && !attachment.source.endsWith('.mp4') && !attachment.source.endsWith('.avi') && !attachment.source.endsWith('.mp3') && !attachment.source.endsWith('.wav') && !attachment.source.endsWith('.zip') && !attachment.source.endsWith('.rar')
                    }" class="fa me-2 fs-2"></i>
                <a [href]="attachment.source" target="_blank" class="text-decoration-none fs-5 m-2">{{ attachment.name }}</a>
            </li>
            </ul>
        </div>
    </div>

</div>
<div class="position-bottom-left mt-10 sticky-footer"
    [ngStyle]="{'background': theme === 'light' ? '#f5f5f5' : '#1e1e2e', 'border-color': theme === 'light' ? '#ddd' : '#333247'}">
    <a [href]="summaryMenu?.button?.url"
        class="text-primary text-sidebar-link fs-6 w-100 text-center d-block opacity-75">
        {{ summaryMenu?.button?.text }} <i class="fa-solid fa-angle-right"></i>
    </a>
</div>
}
<ng-template #radInfoText>
    (R) RESPONSIBLE - Ensure output and end result. <br>
    (A) APPROVE - Veto Power on a decision & sign off actions. <br>
    (D) DELIVER - Provides information, analysis and other support to personal responsible.
</ng-template>