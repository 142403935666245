import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-sonarqube',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class SonarqubeComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'SonarQube is a popular open-source platform for continuous inspection of code quality. It provides developers with tools to analyze source code, detect vulnerabilities, measure technical debt, and enforce coding standards. With support for multiple languages, seamless integration with CI/CD pipelines, and actionable insights, SonarQube helps teams improve code quality and maintainability while reducing risks.'
      }
    },
    {
      type: 'hyperlink',
      value: {
        heading: 'KDI Instances', // Section heading
        links: [
          {
            title: 'KDI Sonarqube Instance',
            url: 'https://sonarqube.kognif.ai/'
          }
        ]
      }
    }
     
  ];
}