import { Component } from '@angular/core';

@Component({
  selector: 'pet-training-modules',
  template: `
    <div class="page-title-container">
      <div class="status-tag-container">
        <span [ngClass]="statusClass" class="status-tag">{{ status }}</span>
      </div>
    </div>
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: [`
    .page-title-container {
      position: relative; /* Position the container relative for overlay */
    }
    .status-tag-container {
      position: absolute; /* Place the tag container relative to the title */
      top: -10px; /* Adjust the distance above the title */
      left: 0; /* Align with the title horizontally */
    }
    .status-tag {
      padding: 0.2rem 0.5rem;
      font-size: 0.85rem;
      font-weight: 500;
      border-radius: 12px;
      color: #fff;
    }
    .badge-draft {
      background-color: #007bff; /* Bootstrap primary blue */
    }
    .badge-published {
      background-color: #28a745; /* Bootstrap success green */
    }
  `]
})

export class TrainingModulesComponent {
  public status: string = 'Draft'; // Change to 'Published' when the modules are ready
  public statusClass: string = 'badge-draft'; // Dynamically set based on status

  public pageData: any = [
    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The ADAPT Training Modules are designed to equip teams with comprehensive knowledge of the ADAPT framework, tools, and best practices. These modules aim to empower individuals with the skills needed to drive impactful results. We are actively working on developing and refining these training modules to ensure they are robust, engaging, and aligned with your needs. Stay tuned for more updates as we continue to build this resource. Detailed module content and schedules will be shared soon.'
      }
    }
  ];
}
