import { Component } from '@angular/core';

@Component({
  selector: 'pet-overview-metrics-kpi',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class OverviewMetricsKpiComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'Metrics and Key Performance Indicators (KPIs) are the backbone of effective product management, enabling teams to track progress, assess performance, and identify improvement areas. The Insights Portal serves as a centralized hub for monitoring and analyzing these metrics, fostering data-driven decision-making and alignment across teams. Metrics and KPIs in the ADAPT framework ensure transparency, accountability, and continuous improvement throughout the product lifecycle. They align team efforts with organizational goals and promote a culture of measurable outcomes.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Key Metrics Categories in Insights Portal',
        items: [
          { name: 'Developer Maturity Index', description: 'Tracks adherence to development best practices, including CI/CD, code reviews, and coding standards.' },
          { name: 'Cycle Time and Lead Time', description: 'Measures the speed of delivering features from inception to production, helping identify bottlenecks.' },
          { name: 'Releasability', description: 'Evaluates the readiness of a product for deployment, including quality assurance and approvals.' },
          { name: 'Undefined User Story Index (UUSI)', description: 'Monitors the percentage of user stories without acceptance criteria to ensure better backlog grooming.' },
          { name: 'Azure Security Score', description: 'Ensures compliance with cloud security standards and identifies potential risks.' },
          { name: 'Last Sprint Status (LSS)', description: 'Summarizes sprint outcomes, highlighting completed vs. incomplete tasks.' },
          { name: 'OS Vulnerability Management', description: 'Checks for compliance and vulnerabilities in open-source components.' },
          { name: 'Open-Source License Compliance', description: 'Ensures proper use and tracking of licenses in open-source dependencies.' }
        ]
      }
    },
    {type:'attachment',
      value:{
        title:'Media/Attachments',
        items:[
          {src:'assets/media/images/adapt-insights.png', description:'ADAPT Insights'}

        ]
      }
    },    
    {
      type: 'superListItem',
      value: {
        title: 'Using Metrics Effectively',
        items: [
          {
            title: 'Key Drivers', description:
            {
              'Objective': ['Foster data-driven decision-making and align team efforts with organizational goals.',
                'Improve transparency and focus on delivering measurable outcomes.'],
              'Activity': ['Regularly review metrics on the Insights Portal.',
                'Analyze trends and patterns to assess team and process health.',
                'Incorporate insights into retrospectives and planning sessions.'],
              'Outcome': ['Enhanced alignment on strategic objectives.',
                'Continuous improvement in team efficiency and product quality.',
                'A transparent and measurable framework for success.']
            }
          }
        ]
      }
    },
           
];
}
