<div class="d-flex flex-column flex-center bgi-no-repeat rounded-top px-5 py-5">
  <h3 class="fw-bold mb-3">ADAPT Applications</h3>
  <!-- <span class="badge bg-primary py-2 px-3">25 pending tasks</span> -->
</div>

<div class="row g-0">
  <div class="col-3">
    <a class="
        d-flex
        flex-column flex-center
        p-2
        bg-hover-light
        cursor-pointer
      " href="{{externalLinks.insights}}">
      <app-keenicon name="abstract-39" class="fs-3x text-primary mb-2"></app-keenicon>
      <span class="fs-6 fw-bold text-gray-800 mb-0 text-center">Insights</span>
      <!-- <span class="fs-7 text-gray-400">eCommerce</span> -->
    </a>
  </div>

  <div class="col-3">
    <a class="
        d-flex
        flex-column flex-center
        p-2
        bg-hover-light
        border-bottom
        cursor-pointer
      " href="{{externalLinks.dmm}}">
      <app-keenicon name="abstract-44" class="fs-3x mb-2 orange-color" ></app-keenicon>
      <span class="fs-6 fw-bold text-gray-800 mb-0 text-center">DMM</span>
      <!-- <span class="fs-7 text-gray-400">Console</span> -->
    </a>
  </div>

  <div class="col-3">
    <a class="
        d-flex
        flex-column flex-center
        p-2
        bg-hover-light
        cursor-pointer
      " href="{{externalLinks.pmm}}">
      <app-keenicon name="abstract-25" class="fs-3x green-color mb-2"></app-keenicon>
      <span class="fs-6 fw-bold text-gray-800 mb-0 text-center">PMM</span>
      <!-- <span class="fs-7 text-gray-400">Pending Tasks</span> -->
    </a>
  </div>

  <div class="col-3">
    <a class="
        d-flex
        flex-column flex-center
        p-2
        bg-hover-light
        cursor-pointer
      " href="{{externalLinks.kbportal}}">
      <app-keenicon name="delivery-24" class="fs-3x voilet-color mb-2"></app-keenicon>
      <span class="fs-6 fw-bold text-gray-800 mb-0 text-center">KB Portal</span>
      <!-- <span class="fs-7 text-gray-400">Latest cases</span> -->
    </a>
  </div>
</div>

