import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-sales-enablement',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class SalesEnablementComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The Sales Enablement process focuses on equipping Customer Success and Growth teams with the knowledge, tools, and resources needed to effectively sell and support the product. This includes directly engaging in strategic sales activities, early adopter programs, and user groups. By aligning product expertise with sales efforts, Sales Enablement ensures consistent messaging, builds trust with customers, and accelerates adoption.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product Manager",
    "description": "Approves sales enablement strategies and ensures alignment with product objectives."
  },
  {
    "name": "Customer Success",
    "description": "Leads the enablement efforts by acting as a bridge between sales teams and product experts."
  },
  {
    "name": "Product Marketing",
    "description": "Develops and updates sales materials, including product books, battlecards, and NOSE frameworks."
  },
  {
    "name": "Growth Team",
    "description": "Executes strategies to expand market reach and improve customer acquisition or retention."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Sales Collaboration and Support",
            "description": {
              "Objective": [
                "Collaborate with sales teams to provide expert insights, facilitate strategic sales, and meet sales targets."
              ],
              "Activity": [
                "Actively participate in user group meetings and sales/channel events.",
                "Provide product demonstrations and answer technical or strategic questions.",
                "Offer direct support to early adopter programs and strategic sales opportunities."
              ],
              "Outcome": [
                "Enhanced sales team confidence and improved alignment with product goals."
              ]
            }
          },
          {
            "title": "Resource Development and Distribution",
            "description": {
              "Objective": [
                "Equip sales teams with tools and resources to drive consistent messaging and effective customer engagement."
              ],
              "Activity": [
                "Create and distribute product books, elevator pitches, battlecards, and NOSE frameworks.",
                "Develop white space/green space analysis to identify opportunities for customer acquisition or expansion.",
                "Maintain up-to-date collateral reflecting the latest product features and positioning."
              ],
              "Outcome": [
                "Comprehensive sales toolkits that align with product strategies and market needs."
              ]
            }
          },
          {
            "title": "Sales Performance Enablement",
            "description": {
              "Objective": [
                "Enable sales teams to achieve and exceed targets through structured programs and incentives."
              ],
              "Activity": [
                "Define sales incentives aligned with strategic goals.",
                "Run workshops and training sessions on product capabilities and positioning.",
                "Facilitate knowledge sharing between sales, product, and customer success teams."
              ],
              "Outcome": [
                "Improved sales effectiveness and alignment with organizational objectives."
              ]
            }
          },
          {
            "title": "Feedback and Iteration",
            "description": {
              "Objective": [
                "Continuously refine enablement tools and strategies based on feedback from sales teams and customers."
              ],
              "Activity": [
                "Gather insights from sales teams and early adopter programs.",
                "Update enablement materials to address identified gaps or new opportunities.",
                "Monitor the impact of enablement efforts and adjust strategies accordingly."
              ],
              "Outcome": [
                "A dynamic and responsive sales enablement process that evolves with market and organizational needs."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Artifacts and Deliverables',
        items:[
          {title:'Product Book', description: 'Comprehensive product guide for sales teams.'
          },
          {title:'Elevator Pitch', description: 'Concise product messaging for quick communication.'
          },
          {title:'NOSE Framework', description: 'Narrative for opportunity selling and expansion.'
          },
          {title:'White Space/Green Space Analysis', description: 'Insights for customer acquisition and growth opportunities.'
          }
        ]
      }
    }
  ];
}