import {
  Component
} from '@angular/core';

@Component({
  selector: 'pet-home-page',
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss'
})
export class HomePageComponent {
}