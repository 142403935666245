import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-training',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class TrainingComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Training is a vital step in ensuring Release and Operational Readiness. This process equips key stakeholders, such as customer support, sales, and operational staff, with the necessary knowledge to support, maintain, and effectively use the new product or update. Training focuses on communicating new features, functionality, troubleshooting procedures, and usage guidelines to bridge the gap between development and end-user experience. By ensuring operational readiness, the training process fosters confidence, reduces downtime, and contributes to a seamless product launch.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product Owner",
    "description": "Ensures training aligns with product objectives and readiness requirements."
  },
  {
    "name": "Customer Success Team",
    "description": "Coordinates training delivery, engages with participants, and collects feedback."
  },
  {
    "name": "Scrum Master",
    "description": "Facilitates the handover process and supports the development team in preparing training materials."
  },
  {
    "name": "Development Team",
    "description": "Provides technical expertise and contributes to creating accurate and effective training resources."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Training Preparation",
            "description": {
              "Objective": [
                "Plan and prepare training materials, sessions, and resources tailored to different stakeholders."
              ],
              "Activity": [
                "Identify the training needs of customer success, sales, and operational teams.",
                "Develop training materials, such as presentations, guides, and video tutorials.",
                "Set up a knowledge base for easy access to user documentation, FAQs, and troubleshooting steps.",
                "Schedule training sessions, ensuring alignment with the product release timeline."
              ],
              "Outcome": [
                "Well-structured training materials and a clear plan for training delivery."
              ]
            }
          },
          {
            "title": "Training Delivery",
            "description": {
              "Objective": [
                "Conduct training sessions to educate teams on new features, functionality, and operational readiness."
              ],
              "Activity": [
                "Run interactive training sessions with hands-on demonstrations.",
                "Use a mix of delivery methods, such as live webinars, recorded videos, and in-person sessions.",
                "Encourage participants to ask questions and provide feedback to clarify doubts."
              ],
              "Outcome": [
                "Informed and confident stakeholders ready to support the product rollout."
              ]
            }
          },
          {
            "title": "Knowledgebase Setup",
            "description": {
              "Objective": [
                "Ensure ongoing accessibility to training resources and documentation."
              ],
              "Activity": [
                "Publish user documentation, guides, and FAQs in an easily accessible knowledge base.",
                "Create troubleshooting steps and operational guidelines for quick reference.",
                "Establish a process to update the knowledge base with new findings or changes."
              ],
              "Outcome": [
                "A centralized and dynamic knowledge repository for continued learning and operational support."
              ]
            }
          },
          {
            "title": "Feedback and Iteration",
            "description": {
              "Objective": [
                "Continuously improve training effectiveness based on feedback from participants."
              ],
              "Activity": [
                "Gather feedback through surveys or post-training assessments.",
                "Identify areas where additional support or clarification is needed.",
                "Refine training materials and methods based on feedback."
              ],
              "Outcome": [
                "Enhanced training materials and an iterative improvement cycle for future sessions."
              ]
            }
          }
        ]
      }
    },    
    
  ];
}