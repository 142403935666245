import { Component } from '@angular/core';

@Component({
  selector: 'pet-using-insight-portal',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class UsingInsightPortalComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'The Insights Portal is an essential tool for teams at Kongsberg Digital to gain actionable insights into product performance, team progress, and operational efficiency. Designed to "make the invisible visible," it enables data-driven decisions, fosters continuous improvement, and highlights opportunities for optimization. Whether you\'re tracking KPIs, identifying bottlenecks, or assessing engineering performance, the Insights Portal serves as your central hub for informed decision-making.'
      }
    },   

    {
      type: 'FileAttachment',
      value: {
        title: 'File Attachments',
        items: [
          {
            name: 'Insights Portal Quick Start Guide',
            src: 'assets/media/pdf/Insights Portal Quick Start Guide.pdf'
          }
        ]
      }
    }
];
}
