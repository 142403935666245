import { Component } from '@angular/core';

@Component({
  selector: 'pet-introduction-adapt',
  template: 
  `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class IntroductionAdaptComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'The ADAPT Framework is a comprehensive structure guiding teams across the entire product lifecycle. It integrates product management and development efforts while ensuring alignment with strategic goals and fostering collaboration across roles. This framework is tailored for the dynamic, fast-paced demands of modern SaaS development and ensures teams deliver value effectively and consistently.'
      }
    },
    {
      type: 'intro',
      value: {
        mainText: 'What is ADAPT?',
        subText: 'ADAPT stands for Agile Development (and) Adaptive Product Transformation. Our foundation pillars are based on Alignment, Accountability, Prioritization, and Transformation. These pillars emphasize the importance of aligning teams with business objectives, delivering value efficiently, fostering accountability across roles, and embracing adaptability in the face of evolving priorities.'
      }
    },     
    {
      type:'listItem',
      value:{
        title:'Key Features of ADAPT',
        items:[
          {name:'End-to-End Lifecycle Coverage', description:'Guides teams from strategy formation to scaling products in the market.'},
          {name:'Flex-and-Fix Approach', description:'Combines structured processes with flexibility to adapt workflows to the team and organizational context.'},
          {name:'Outcome-Focused Processes', description:'Every stage and activity is designed to drive measurable outcomes.'},
          {name:'Empowered Teams', description:'Provides autonomy within a structured framework, allowing teams to innovate while staying aligned with broader objectives.'}
        ]
      }
    },
    {
      type: 'intro',
      value: {
        mainText: 'Why ADAPT Matters?',
        subText: 'In an industry where priorities shift quickly, and customer expectations evolve continuously, a framework like ADAPT is essential. It ensures that every product initiative is grounded in real user value, executed with precision, and scalable for future needs.'
      }
    }, 
    {
      type: 'intro',
      value: {
        mainText: 'How ADAPT Differs from Other Frameworks',
        subText: 'Unlike prescriptive frameworks like SAFe or LeSS, ADAPT provides a balance between structure and flexibility. It emphasizes continuous discovery, user-centricity, and iterative improvements, allowing teams to pivot quickly without losing sight of strategic goals.'
      }
    },     
];
}
