import { Component } from '@angular/core';

@Component({
  selector: 'pet-sprint-planning',
  template: 
  `
  <p>
      Home > ADAPT Lifecycle Stages > Development & Product Readiness > Sprint Planning
  </p>
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class SprintPlanningComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'Sprint Planning is a core activity in the Agile framework that initiates each sprint. The purpose is to define what work will be completed in the upcoming sprint and how it will be accomplished. This involves reviewing the prioritized backlog, setting clear sprint goals, and breaking down work into manageable tasks. The outcome of Sprint Planning is a well-defined and achievable sprint backlog, ensuring alignment with the overall product roadmap and strategic objectives.'
      }
    },
    {
      type:'listItem',
      value:{
        title:'Roles and Responsibilities',
        items:[
          {
            "name": "Product Owner",
            "description": "Defines the sprint goal, prioritizes backlog items, and ensures alignment with strategic objectives."
          },
          {
            "name": "Scrum Master",
            "description": "Facilitates sprint planning, ensuring the team adheres to Agile practices and focuses on deliverables."
          },
          {
            "name": "Development Team",
            "description": "Collaborates on effort estimation, task breakdown, and identifies dependencies for smooth execution."
          },
          {
            "name": "Tech Lead",
            "description": "Reviews technical dependencies and ensures alignment with development priorities and external teams."
          },
          {
            "name": "QA Lead",
            "description": "Ensures testability of user stories and identifies potential testing challenges during planning."
          }
        ]
      }
    },
    {
      type:'checkListItem',
      value:{
        title:'Prerequisites',
        items:[
          {id:1, text:'A well-prioritized and refined product backlog, ready for sprint execution.'},
          {id:2, text:'Stakeholder alignment on roadmap priorities to inform sprint goals.'},
          {id:3, text:'Clear acceptance criteria for user stories to ensure a shared understanding of "done."'}
        ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Sprint Goal Definition",
            "description": {
              "Objective": [
                "Set a clear and concise goal that aligns with the product roadmap and strategic objectives."
              ],
              "Activity": [
                "The Product Owner proposes a sprint goal based on the most valuable backlog items.",
                "The team collaborates to refine and finalize the goal."
              ],
              "Outcome": [
                "A sprint goal that provides direction and purpose for the sprint."
              ]
            }
          },
          {
            "title": "Backlog Review and Prioritization",
            "description": {
              "Objective": [
                "Ensure that the top-priority backlog items are ready for the sprint."
              ],
              "Activity": [
                "The Product Owner presents the highest-priority items from the backlog.",
                "The team discusses each item to understand its requirements, dependencies, and alignment with the sprint goal."
              ],
              "Outcome": [
                "A list of prioritized and well-understood user stories for the sprint."
              ]
            }
          },
          {
            "title": "Effort Estimation",
            "description": {
              "Objective": [
                "Estimate the effort required for each backlog item to ensure realistic sprint commitments."
              ],
              "Activity": [
                "Use methods like Planning Poker, T-shirt sizing, or story points to achieve consensus on estimates.",
                "Leverage team discussions to align on complexity and effort expectations."
              ],
              "Outcome": [
                "Estimated user stories with a shared understanding of complexity and effort."
              ]
            }
          },
          {
            "title": "Task Breakdown and Assignment",
            "description": {
              "Objective": [
                "Break down user stories into actionable tasks and assign responsibilities to the team."
              ],
              "Activity": [
                "The team collaboratively breaks down stories into tasks.",
                "Estimate the effort for each task and assign responsibilities based on team capacity."
              ],
              "Outcome": [
                "A sprint backlog with detailed tasks, ready for execution."
              ]
            }
          },
          {
            "title": "Capacity Planning and Commitment",
            "description": {
              "Objective": [
                "Ensure the team's capacity aligns with the planned sprint scope."
              ],
              "Activity": [
                "The Scrum Master facilitates a discussion on team availability, factoring in holidays and other commitments.",
                "The team commits to the sprint backlog based on capacity and confidence in delivery."
              ],
              "Outcome": [
                "A committed sprint backlog, with the team confident in delivering the planned work."
              ]
            }
          },
          {
            "title": "Dependency Identification",
            "description": {
              "Objective": [
                "Identify and address dependencies to ensure smooth sprint execution."
              ],
              "Activity": [
                "The Tech Lead and team review each item to surface dependencies.",
                "Coordinate with external teams or stakeholders to address dependencies early."
              ],
              "Outcome": [
                "An updated sprint plan with a clear understanding of dependencies and strategies to address them."
              ]
            }
          },
          {
            "title": "Acceptance Criteria Review",
            "description": {
              "Objective": [
                "Ensure alignment on acceptance criteria for each user story."
              ],
              "Activity": [
                "The Product Owner and development team review acceptance criteria.",
                "Make necessary adjustments to ensure clarity and alignment with the sprint goal."
              ],
              "Outcome": [
                "Well-defined user stories with clear 'done' criteria, ready for development."
              ]
            }
          }
        ]
      }
    },
    {type:'attachment',
      value:{
        title:'Media/Attachments',
        items:[
          {src:'assets/media/images/adapt-sprintplanning.png', description:'A typical 3-week Sprint Planning in KDI'}

        ]
      }
    },     
{type:'listItem',
  value:{
    title:'Practical Examples',
    items:[
      {name:'Example 1:', description:'During Sprint Planning, the team uses Planning Poker to estimate a complex user story. Through discussion, they uncover a major dependency that requires coordination with another team.'},
      {name:'Example 2:', description:'The Product Owner presents a high-priority feature, and the team works together to break it down into smaller, more manageable tasks, ensuring the work can be completed within the sprint.'}
      
    ]
  }
},
{
  type:'checkListItem',
  value:{
    title:'Tools / Resources / Templates',
    items:[
      {id:1, text:'Azure DevOps for organizing and documenting sprint goals, backlog items, and team commitments.'},
      {id:2, text:'Estimation Guide: A reference document on how to use Planning Poker or T-shirt sizing effectively.'}
    ]
  }
},
];
}
