import { Component } from '@angular/core';

@Component({
  selector: 'pet-staging',
  template: 
  `
  <p>
      Home > ADAPT Lifecycle Stages > Development & Product Readiness > Staging
  </p>
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class StagingComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'The Staging phase is a critical step in ensuring the software is ready for production deployment. This stage provides a controlled, pre-production environment that closely replicates the live (production) setup. It is designed to facilitate End-to-End Integration Testing as well as Performance, Scalability, and Reliability Testing. While the use of a staging environment is currently optional, it is highly recommended to mitigate risks and ensure a smooth transition to production.'
      }
    },
    {
      type:'listItem',
      value:{
        title:'Roles and Responsibilities',
        items:[
          {name:'Product Manager/Owner*', description:'Oversees the readiness of the staging environment and signs off on critical tests.'},
          {name:'Tech Lead', description:'Configures and maintains the staging setup, ensuring it mirrors production.'},
          {name:'QA Team', description:'Conducts End-to-End Integration and Performance Testing.'},
          {name:'DevOps Engineer', description:'Manages the deployment pipeline and ensures the staging environment is stable and reliable.'},
          {name:'Scrum Master', description:'Facilitates the coordination and scheduling of staging activities.'}
        ]
      }
    },
    {
      type:'checkListItem',
      value:{
        title:'Prerequisites',
        items:[
          {id:1, text:'Completion of the Product Readiness (QA) Signoff phase, including all functional, regression, and security tests.'},
          {id:2, text:'Availability of a configured staging environment that matches the production setup.'},
          {id:3, text:'Defined criteria for End-to-End Integration, Performance, Scalability, and Reliability Testing.'}
        ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Setup Staging Environment",
            "description": {
              "Objective": [
                "Ensure a production-like environment to validate application readiness before deployment."
              ],
              "Activity": [
                "Replicate the production environment configuration, including databases, third-party integrations, and network settings.",
                "Ensure that all necessary access controls and permissions are in place."
              ],
              "Outcome": [
                "A staging environment that accurately reflects production, ready for comprehensive testing."
              ]
            }
          },
          {
            "title": "End-to-End Integration Testing",
            "description": {
              "Objective": [
                "Validate that all components of the system work seamlessly together."
              ],
              "Activity": [
                "Check for data flow consistency, API integrations, and error handling across the application.",
                "Test the interoperability of all system modules and external dependencies."
              ],
              "Outcome": [
                "Confidence that the application functions as expected across all integrated components."
              ]
            }
          },
          {
            "title": "Performance Testing",
            "description": {
              "Objective": [
                "Ensure the system can handle expected loads and stress conditions efficiently."
              ],
              "Activity": [
                "Simulate load conditions to measure response times, throughput, and resource usage.",
                "Test the system's ability to scale up and handle increased load or demand.",
                "Assess the system's reliability and stability over extended periods.",
                "Identify performance bottlenecks and optimize system performance."
              ],
              "Outcome": [
                "Validated performance metrics, scalability, and reliability under stress conditions."
              ]
            }
          },
          {
            "title": "Controlled Access",
            "description": {
              "Objective": [
                "Secure the staging environment to prevent unauthorized access and ensure readiness for testing."
              ],
              "Activity": [
                "Limit access to the staging environment to authorized personnel only.",
                "Ensure a rollback plan is in place if any issues arise during staging tests."
              ],
              "Outcome": [
                "A secure and controlled environment for conducting staging tests effectively."
              ]
            }
          }
        ]
      }
    },
{type:'listItem',
  value:{
    title:'Practical Examples',
    items:[
      {name:'Example 1:', description:'Running a full load test on the staging environment to ensure that the system can handle peak traffic loads without performance degradation.'},
      {name:'Example 2:', description:'Conducting an integration test to ensure that data flows correctly between microservices and external APIs.'}
      
    ]
  }
},
{
  type:'checkListItem',
  value:{
    title:'Tools / Resources / Templates',
    items:[
      {id:1, text:'CI/CD Pipeline Tools: Azure DevOps'},
      {id:2, text:'Testing Tools: JMeter, Postman, SoapUI'}
    ]
  }
},
];
}
