import { Component } from '@angular/core';

@Component({
  selector: 'pet-product-designer',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ProductDesignerComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Role Definition',
        subText:'Product Designers are crucial in creating impactful, user-centered experiences that align with strategic business goals. They bridge user needs with business objectives, working across all product lifecycle stages to enhance both usability and engagement.'
      }
    },     
    {
      type: 'checkListItem',
      value: {
        title: 'Prerequisites',
        items: [
          { id: 1, text: 'Analytical & Research Skills: Deep understanding of user-centered research methods, data analysis, and persona development.' },
          { id: 1, text: 'Design Expertise: Skilled in design principles, wireframing, prototyping, and maintaining design systems to ensure consistency.' },
          { id: 1, text: 'Cross-Functional Collaboration: Ability to effectively partner with Product Managers, development teams, and stakeholders to align design with business strategy.' }
        ]
      }
    },
    {
      type: 'multibulletList',
      value: {
        title: 'Responsibilities & Deliverables',
        items: [
          {
            name: 'Discovery & Research',
            description: [
              'Drive user research efforts',
              'Define user personas',
              'Translate insights into actionable design requirements'
            ]
          },
          {
            name: 'Ideation & Prototyping',
            description: [
              'Create wireframes',
              'Low- and high-fidelity prototypes',
              'Conduct iterative usability testing to refine designs based on real feedback.',
              'Iterated on user feedback'
            ]
          },
          {
            name: 'Design System Development',
            description: [
              'Establish and maintain a design system that promotes visual and functional consistency across all products.'
            ]
          },
          {
            name: 'Cross-Functional Alignment',
            description: [
              'Actively engage in strategic planning sessions.',
              'Sprint reviews, Gromming and design critiques to ensure design objectives align with development timelines and strategic goals.'
            ]
          },
          {
            name: 'Continuous Improvement',
            description: [
              'Leverage user feedback, analytics.',
              'Leverage performance metrics post-launch to refine and evolve the product design.',
              'Ensuring an ever-improving user experience.'
            ]
          }
        ]
      }
    },
    {
      type: 'multibulletList',
      value: {
        title: 'Collaboration Strategy for Product Design',
        items: [
          {
            name: 'Product Manager',
            description: [
              'Alignment in Strategy and Roadmap: Regularly sync on product vision, key features, and user research to ensure design goals align with business objectives.',
              'Feedback Loops: Participate in roadmap planning sessions to provide early design insights.'
            ]
          },
          {
            name: 'Product Owner',
            description: [
              'Backlog Integration: Collaborate on prioritizing design tasks within the product backlog to address user needs and technical feasibility.',
              'Sprint Planning: Align design deliverables to meet sprint goals, ensuring readiness for development.'
            ]
          },
          {
            name: 'Scrum Master',
            description: [
              'Workflow Optimization: Coordinate on sprint timelines and remove roadblocks affecting the design team.',
              'Agile Practices: Establish a smooth dual-track agile approach where design runs ahead of development.'
            ]
          },
          {
            name: 'Development Team',
            description: [
              'Design Handoffs: Use collaborative tools (e.g., Figma, Zeplin) for efficient design-to-development handoffs.',
              'Technical Feasibility Reviews: Engage in regular check-ins to discuss design feasibility, offering support as needed.'
            ]
          },
          {
            name: 'Security Champion',
            description: [
              'Security-Integrated Design: Collaborate early to incorporate security guidelines into UI/UX, ensuring secure user flows.',
              'Compliance Checks: Review designs together for compliance with security standards.'
            ]
          },
          {
            name: 'Release Manager',
            description: [
              'Release-Ready Designs: Align on release timelines, ensuring design elements are polished and tested before deployment.',
              'Launch Coordination: Sync for seamless, user-ready release processes, focusing on design consistency and quality.'
            ]
          },
          {
            name: 'Best Practice Champion (BPC)',
            description: [
              'Process Standardization: Work together to establish design standards and best practices across teams.',
              'Feedback and Iteration: Leverage the BPC as a feedback channel to refine design processes and maintain alignment with ADAPT guidelines.'
            ]
          }
        ]
      }
    },
];
}
