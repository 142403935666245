import { Component } from '@angular/core';
import { THEME } from 'src/app/app.constants';

@Component({
  selector: 'pet-best-practices',
  template: `
  <div class="card-body d-flex justify-content-between flex-column py-3">
  <div class="m-0"></div>
  <div class="table-responsive mb-n2">
    <table class="table table-row-dashed gs-0 gy-4">
      <thead>
        <tr class="fs-7 fw-bold border-0 text-gray-500">
          <th class="min-w-300px">Best Practices</th>
          <th class="min-w-100px">DESCRIPTION</th>
        </tr>
      </thead>
      
      <tbody>
        <tr *ngFor="let page of pages">
          <td>
            <a [routerLink]="page.path"  class="text-gray-600 fw-bold text-hover-primary mb-1 fs-6">{{ page.title }}</a>
          </td>
          <td class="d-flex align-items-center border-0">
            <span class="fw-bold text-gray-800 fs-6 me-3">{{ page.description }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
  `,
  styles: [`
  `],
})
export class BestPracticesComponent {
  isLightTheme = THEME === 'light';
  selectedPage: any;
  public pages = [
    {
      title: 'Threat Modeling',
      path: '/information-support/techniques-&-practices/best-practices/threat-modeling',
      description: 'A process to identify and mitigate potential security threats.'
    },
    {
      title: 'Work Estimation',
      path: '/information-support/techniques-&-practices/best-practices/work-estimation',
      description: 'Techniques to estimate the effort required for tasks.'
    }
  ];
}
