import { Component } from '@angular/core';

@Component({
  selector: 'pet-continuous-discovery-validation',
  template: 
  `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})


export class ContinuousDiscoveryValidationComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'The Continuous Discovery Process is a structured yet flexible approach designed to gaining deep understanding of what’s valuable to users by exploring a problem space and validating candidate product concepts. Using structured discovery approaches like journey mapping, prototyping to define and test ideas. By leveraging such approaches, this process helps ensure that product development is customer-centric, focused on solving unmet needs, and aligned with strategic objectives.'
      }
    },
    {
      type:'listItem',
      value:{
        title:'Roles and Responsibilities',
        items:[
          {
            "name": "Product VP",
            "description": "Oversees the discovery process to ensure alignment with strategic objectives and customer needs."
          },
          {
            "name": "Product Manager",
            "description": "Leads the discovery efforts, facilitating collaboration among stakeholders and ensuring actionable outcomes. Responsible for balancing viability with desirability and feasibility."
          },
          {
            "name": "Product Owner",
            "description": "Bridges the gap between discovery insights and user requirements by translating validated findings into fully documented backlog items."
          },
          {
            "name": "Tech Lead",
            "description": "Assesses technical feasibility and identifies potential risks in proposed solutions. Provides input into alternative ways to solve the problem."
          },
          {
            "name": "Product Design",
            "description": "Creates personas, journey maps, and creating prototypes or uses other creative methods to test and visualize product concepts thus helping to inform both desirability and viability."
          },
          {
            "name": "Customer Success",
            "description": "Provides insights from customer interactions and supports validation efforts. Plays an active role in identifying appropriate customers/prospects with whom we wish to discover opportunities."
          },
          {
            "name": "Growth",
            "description": "Contributes market insights and helps prioritize opportunities based on strategic impact. Plays an active role in identifying appropriate customers/prospects with whom we wish to discover opportunities."
          },
          {
            "name": "Delivery",
            "description": "Ensures alignment between validated opportunities and development feasibility. Provides early adopter feedback in an agreed/ structured format"
          }
              ]
      }
    },
    {
      type:'checkListItem',
      value:{
        title:'Prerequisites',
        items:[
          {id:1, text:'Clear understanding of market conditions, strategic objectives and alignment with the product vision.'},
          {id:2, text:'Defined roles and responsibilities for core stakeholders (e.g., Product, Design, Tech Lead).'}
        ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Contexts and Approaches",
        "subTitle": "Continuous discovery is inherently iterative, fluid, and tailored to different situations, customer needs, and market dynamics. Rather than adhering to a linear, predefined process, it leverages adaptable contexts and targeted approaches to explore opportunities, validate ideas, and drive meaningful outcomes.",
        "items": [
          {
            "title": "Inspiration",
            "description": {
              "Objective": [
                "Explore and uncover customer needs, market opportunities, and pain points to inspire innovative solutions."
              ],
              "Activity": [
                "Conduct qualitative research through interviews, focus groups, and observational studies.",
                "Collaborate with cross-functional teams to brainstorm opportunities and align on problem spaces.",
                "Create customer journey maps and identify critical pain points."
              ],
              "Outcome": [
                "Defined Problem Statements and initial Personas representing target user groups."
              ]
            }
          },
          {
            "title": "Validation",
            "description": {
              "Objective": [
                "Test and validate ideas, concepts, and assumptions with real customers to reduce uncertainty."
              ],
              "Activity": [
                "Develop low-fidelity prototypes or mockups to represent product ideas.",
                "Run usability tests, A/B tests, and surveys to gather quantitative and qualitative feedback.",
                "Iterate on designs and concepts based on user feedback and insights."
              ],
              "Outcome": [
                "Validated Prototypes and refined Personas with actionable insights for prioritization."
              ]
            }
          },
          {
            "title": "Optimization",
            "description": {
              "Objective": [
                "Refine validated opportunities and integrate them into the product roadmap for execution."
              ],
              "Activity": [
                "Prioritize features and concepts using techniques like RICE or WSJF frameworks.",
                "Collaborate with stakeholders to align on roadmap priorities and adjust accordingly.",
                "Estimate development effort using techniques such as T-shirt sizing or Jobs-to-be-Done frameworks."
              ],
              "Outcome": [
                "A Prioritized Opportunity Backlog and updated Roadmap ready for sprint planning."
              ]
            }
          }
        ]
      }
    },
    {type:'attachment',
      value:{
        title:'Media/Attachments (4)',
        items:[
          {src:'assets/media/images/cdv-2.PNG', description:'Discovery Process'},
          {src:'assets/media/images/cdv-3.PNG', description:'Discovery Process'},
          {src:'assets/media/images/cdv-4.PNG', description:'Discovery Process'},
          {src:'assets/media/images/cdv-5.PNG', description:'Discovery Process'}
        ]
      }
    }, 
    
    {
      type: 'multibulletList',
      value: {
        title: 'Iterative Process Breakdown',
        items: [
          {
            name: 'Phased Approach',
            description: [
              'The process emphasizes the Inspiration, Validation, and Optimization phases, ensuring a continuous flow from ideation to actionable, validated insights. Each phase has distinct objectives and outcomes:',
              'Inspiration: Gather raw insights from customers, markets, and stakeholders to identify problems and opportunities.',
              'Validation: Test hypotheses, ideas, and prototypes with customers to ensure feasibility and desirability.',
              'Optimization: Refine validated concepts into prioritized, actionable deliverables for development.'
            ]
          },
          {
            name: 'Collaborative Team Dynamics',
            description: [
              'Product managers, designers, tech leads, and customer-facing roles (like Customer Success) work closely to ensure all perspectives are considered, maintaining alignment between business goals and customer needs.'
            ]
          },
          {
            name: 'Artifacts as Anchors',
            description: [
              'The process creates deliverables like Personas, Customer Journey Maps, Problem Statements, and Prototypes that drive iterative learning and alignment.',
              'These artifacts bridge the gap between strategy and execution, maintaining traceability.',
              'They should support the Empowered Product Teams approach of delivering innovative solutions that delight customer BUT that also drive value for the business'
            ]
          },
          {
            name: 'Use of Techniques and Tools',
            description: [
              'Feedback loops are central, ensuring every iteration informs the next. This aligns the process with lean and agile principles for adaptability and learning.'
            ]
          },
          {
            name: 'Continuous Feedback and Iteration',
            description: [
              'Description: Data from user testing, experiments, and surveys.',
              'Example: "70% of users found the new design intuitive and efficient."'
            ]
          },
          {
            name: 'Integration with the ADAPT Framework',
            description: [
              'Discovery is not isolated; it connects with roadmap planning, sprint execution, and long-term strategic alignment.',
              'The process addresses common pitfalls in traditional discovery methods by focusing on customer-centricity and avoiding internal biases. It requires a commitment to cross-functional collaboration and alignment at every step.'
            ]
          }
        ]
      }
    },


    {
      type: 'multibulletList',
      value: {
        title: 'Artifacts & Deliverables (Iterative & Any)',
        items: [
          {
            name: 'Problem Statements',
            description: [
              'Description: Clearly defined problems derived from user and market insights.',
              'Example: "Users find it hard to locate the analytics dashboard due to poor navigation."'
            ]
          },
          {
            name: 'Customer Personas',
            description: [
              'Description: Detailed profiles of target users, their goals, and challenges.',
              'Example: "Data Analyst: Needs detailed reports with flexible filtering options."'
            ]
          },
          {
            name: 'Journey Maps/User Workflows',
            description: [
              'Description: Visual representation of user experiences and pain points.',
              'Example: "Step-by-step journey of a user generating custom reports."'
            ]
          },
          {
            name: 'Low-Fidelity Prototypes',
            description: [
              'Description: Early sketches or wireframes for initial idea testing.',
              'Example: "Wireframe of a simplified reporting interface."'
            ]
          },
          {
            name: 'Validation Results',
            description: [
              'Description: Data from user testing, experiments, and surveys.',
              'Example: "70% of users found the new design intuitive and efficient."'
            ]
          },
          {
            name: 'Prioritized Opportunity Backlog',
            description: [
              'Description: Ranked list of validated opportunities.',
              'Example: "Simplify report generation (RICE score: 40)."'
            ]
          },
          {
            name: 'Customer Feedback Summaries',
            description: [
              'Description: Aggregated insights from user feedback sessions.',
              'Example: "Users consistently reported difficulty in finding advanced settings."'
            ]
          },
          {
            name: 'Risk Assessment and Mitigation Plans',
            description: [
              'Description: Identified risks with actionable strategies.',
              'Example: Example: "Risk: Low user adoption of new features; Mitigation: Gradual rollout with feedback loops."'
            ]
          }
        ]
      }
    },
{
  type:'checkListItem',
  value:{
    title:'How to Use These Artifacts',
    items:[
      {id:1, text:'Communication: Share findings and recommendations with stakeholders to align on priorities.'},
      {id:2, text:'Validation: Use data-driven insights to ensure solutions address real user needs.'},
      {id:3, text:'Prioritization: Focus efforts on high-impact opportunities backed by validation.'},
      {id:4, text:'Continuity: Establish feedback loops for ongoing improvement and adaptation.'}
    ]
  }
},
];
}
