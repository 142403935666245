import { Component } from '@angular/core';

@Component({
  selector: 'pet-release-manager',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ReleaseManagerComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Role Definition',
        subText:'A Release Manager in KDI orchestrates the release process for the relevant product, ensures the listed deliverables and responsibilities.'
      }
    },     
    {
      type: 'checkListItem',
      value: {
        title: 'Prerequisites',
        items: [
          { id: 1, text: 'Strong understanding of SDLC principles and continuous delivery pipelines.' },
          { id: 2, text: 'Ability to manage risks and resolve issues throughout the release process.' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Deliverables',
        items: [
          { id: 1, text: 'Release cadence is defined, planned, executed, and deployed smoothly in collaboration with Product Delivery team.' },
          { id: 2, text: 'Acts as a bridge between product development, operations, and various stakeholder needs, emphasizing the importance of timing, quality, and efficiency in software deployment.' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Responsibilities',
        items: [
          { id: 1, text: 'Release Planning: Align release schedules with product roadmaps, coordinating with product management to ensure timely delivery of features.' },
          { id: 2, text: 'Cross-Functional Coordination: Collaborate with development, QA, and operations teams to ensure seamless transitions from build through to deployment.' },
          { id: 3, text: 'Release Readiness: Contribute in the approval process of release review gates, ensuring all aspects of the release are vetted and approved.' },
          { id: 4, text: 'Deployment Management: Oversee the deployment process, including scheduling, resource allocation, and post-deployment support.' },
          { id: 5, text: 'Risk Management and Mitigation: Proactively identify potential release risks, developing mitigation strategies to address them effectively.' },
          { id: 6, text: 'Compliance and Reporting: Ensure all release activities comply with regulatory standards and report on release progress and outcomes.' },
          { id: 7, text: 'Continuous Improvement: Advocate for and implement improvements in the release process, driving efficiency and effectiveness in line with ADAPT principles.' }
        ]
      }
    }, 
];
}
