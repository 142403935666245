import { Component } from '@angular/core';

@Component({
  selector: 'pet-roadmap',
  template: `
    <div class="page-title-container">
      <div class="status-tag-container">
        <span [ngClass]="statusClass" class="status-tag">{{ status }}</span>
      </div>
    </div>
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: [`
    .page-title-container {
      position: relative; /* Position the container relative for overlay */
    }
    .status-tag-container {
      position: absolute; /* Place the tag container relative to the title */
      top: -10px; /* Adjust the distance above the title */
      left: 0; /* Align with the title horizontally */
    }
    .status-tag {
      padding: 0.2rem 0.5rem;
      font-size: 0.85rem;
      font-weight: 500;
      border-radius: 12px;
      color: #fff;
    }
    .badge-draft {
      background-color: #007bff; /* Bootstrap primary blue */
    }
    .badge-published {
      background-color: #28a745; /* Bootstrap success green */
    }
  `]
})

export class RoadmapComponent {
  public status: string = 'Draft'; // Change to 'Published' when the roadmap is finalized
  public statusClass: string = 'badge-draft'; // Dynamically set based on status

  public pageData: any = [
    {
      type: 'intro',
      value: {
        mainText: 'ADAPT and Insights Portal Roadmap',
        subText: 'The roadmap for the ADAPT Framework and the Insights Portal outlines our planned developments and strategic milestones within the Product Ecosystem & Transformation space. This roadmap ensures transparency and alignment across all stakeholders, enabling focused efforts on delivering impactful updates. Stay tuned as we refine and share our upcoming initiatives for these critical tools.'
      }
    }
  ];
}
