<div class="container-fluid">
    <div class="row">
        <div class="col-md-8 col-sm-12">
            <div id="sunBurstChart" class="svgGraph">
            </div>
        </div>
        <div class="col-md-4 col-sm-12">
            <pet-summary-menu-view [summaryMenu]="summaryMenuData"></pet-summary-menu-view>
        </div>
    </div>
</div>

<!-- <pet-summary-menu-view [summaryMenu]="summaryMenuData"></pet-summary-menu-view> -->
  
  