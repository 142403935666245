import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-market-segmentation',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class MarketSegmentationComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Market segmentation is the process of using discovery insights and other relevant data to identify attractive market segments. These segments can then be targeted with tailored propositions, driving product investment decisions. This process supports strategic alignment and ensures resources are directed toward high-potential opportunities.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product VP",
    "description": "Approves the identified market segments and ensures alignment with the strategic roadmap."
  },
  {
    "name": "Product Manager",
    "description": "Leads the segmentation effort by analyzing data, defining segments, and ensuring actionable insights are derived."
  },
  {
    "name": "Customer Success",
    "description": "Provides customer feedback and insights that inform segmentation decisions."
  },
  {
    "name": "Growth",
    "description": "Assesses growth opportunities and ensures alignment with marketing strategies."
  },
  {
    "name": "Strategy (CSO)",
    "description": "Provides strategic guidance and ensures segmentation supports overall business goals."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Micro Segmentation",
            "description": {
              "Objective": [
                "Identify specific customer groups with shared characteristics or behaviors to refine targeting and propositions."
              ],
              "Activity": [
                "Analyze discovery insights and customer data to identify trends and patterns.",
                "Conduct opportunity sizing to evaluate the potential value of each segment.",
                "Use market modeling techniques to validate assumptions and prioritize high-potential segments."
              ],
              "Outcome": [
                "Clearly defined and actionable market segments with data-driven opportunity sizing."
              ]
            }
          },
          {
            "title": "Strategic Imperatives Mapping",
            "description": {
              "Objective": [
                "Align identified market segments with the organization’s strategic imperatives (e.g., WTP, HTW, WYN)."
              ],
              "Activity": [
                "Map each segment to strategic objectives using tools like the Strategy Cascade.",
                "Assess how segments contribute to long-term business goals and prioritize accordingly.",
                "Refine segmentation based on alignment with high-value imperatives such as WNTP, HNTW, and WWDN."
              ],
              "Outcome": [
                "Strategically aligned market segments ready for product and investment focus."
              ]
            }
          }
        ]
      }
    },    
    {
      type: 'superListItem',
      value: {
        title: 'Practical Examples',
        items: [
          {
            title: 'Micro Segmentation', description:
            {
              'Objective': ['A SaaS product targeting manufacturing industries could identify micro-segments like "mid-sized manufacturers in the automotive sector" or "large-scale manufacturers in renewable energy equipment."'],
              'Activity': [],
              'Outcome': ['Opportunity sizing reveals that renewable energy manufacturers show higher growth potential, guiding prioritization.']
            }
          },
          {
            title: 'Strategic Imperatives Mapping', description:
            {
              'Objective': ['Aligning the renewable energy segment with corporate objectives such as "Win New Markets" and "Expand Key Verticals" helps ensure focus on strategic growth areas.'],
              'Activity': [],
              'Outcome': []
            }
          }
        ]
      }
    },
    
  ];
}