import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-partner-engagement',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class PartnerEngagementComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Partner Engagement involves identifying, engaging, and managing strategic partners who contribute to the success of the product or service. These partners may provide strategic, technical, or deployment-related resources and skills. This process includes aligning partnership goals with business objectives, formalizing terms through contract agreements, and ensuring effective collaboration. Partner engagement ensures that all external collaborations enhance product delivery, scalability, and market success.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product Manager",
    "description": "Defines the strategic needs and ensures alignment between product objectives and partner capabilities."
  },
  {
    "name": "Product Marketing",
    "description": "Leads partner engagement initiatives and ensures partners are aligned with the go-to-market strategy."
  },
  {
    "name": "Customer Success Team",
    "description": "Supports partner enablement and ensures customer-focused outcomes through effective collaboration."
  },
  {
    "name": "Growth Team",
    "description": "Drives partner-related growth opportunities and tracks measurable outcomes."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Identifying Strategic Partners",
            "description": {
              "Objective": [
                "Identify potential partners who align with product strategy and business goals."
              ],
              "Activity": [
                "Assess gaps in technical, deployment, or strategic capabilities that partners can fill.",
                "Research potential partners and their track record in the relevant domain.",
                "Shortlist partners based on strategic alignment, technical expertise, and market influence."
              ],
              "Outcome": [
                "A curated list of potential partners who complement product goals."
              ]
            }
          },
          {
            "title": "Engaging and Negotiating",
            "description": {
              "Objective": [
                "Establish mutually beneficial relationships with selected partners."
              ],
              "Activity": [
                "Initiate discussions to understand partner capabilities, expectations, and goals.",
                "Define collaboration scope, responsibilities, and deliverables.",
                "Negotiate and formalize contract terms, ensuring alignment with governance and investment guidelines."
              ],
              "Outcome": [
                "Signed partnership agreements that clearly define roles and responsibilities."
              ]
            }
          },
          {
            "title": "Managing Ongoing Collaboration",
            "description": {
              "Objective": [
                "Ensure effective collaboration and alignment throughout the partnership lifecycle."
              ],
              "Activity": [
                "Set up regular check-ins and performance reviews with partners.",
                "Track deliverables and resolve issues proactively.",
                "Gather feedback to improve partner engagement and maximize value."
              ],
              "Outcome": [
                "A collaborative and productive partnership delivering measurable business outcomes."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Artifacts and Deliverables',
        items:[
          {title:'Partner Engagement Framework', description: 'A structured document outlining the scope, objectives, and criteria for selecting and working with partners.'
          },
          {title:'Contract Agreements', description: 'Legally binding documents formalizing the terms of partnership.'
          },
          {title:'Partner Performance Metrics', description: 'Key performance indicators (KPIs) to track partner contributions and success.'
          },
          {title:'Feedback Reports', description: 'Regular reports capturing feedback from partners and internal teams.'
          },
          {title:'Collaboration Roadmap', description: 'Timeline and milestones for partnership deliverables.'
          }
        ]
      }
    }
  ];
}