import { Component } from '@angular/core';

@Component({
  selector: 'pet-threat-modeling',
  template: `
  <hr>
    <h2>
      threat-modeling works!
    </h2>
    <div style="text-align: right;">
      <button class="btn btn-secondary" (click)="goBack()">
        <i class="fa fa-arrow-left"></i> Back
      </button>
    </div>
  `,
  styles: ``
})
export class ThreatModelingComponent {
  public goBack() {
    window.history.back();
  }

}
