import { Component } from '@angular/core';

@Component({
  selector: 'pet-product-marketing-manager',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ProductMarketingManagerComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Role Definition',
        subText:'A Product Marketing Manager (PMM) is responsible for positioning a product in the market to drive customer engagement and sales. They conduct market research, define clear messaging, and create go-to-market strategies for product launches. PMMs work closely with sales & product managers. They gather customer insights to refine product features and collaborate with marketing to generate demand through content. By tracking performance, they continuously optimize strategies to ensure the product resonates with customers and meets business goals.'
      }
    },     
    {
      type: 'checkListItem',
      value: {
        title: 'Deliverables',
        items: [
          { id: 1, text: 'Launch plan' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Responsibilities',
        items: [
          { id: 1, text: 'Work with product managers on feeding back information on market segments, competitor landscape, buyer personas' },
          { id: 2, text: 'Create deeply persuasive and differentiated messaging - reflected in videos, e-guides, white papers, blogs, articles, surveys, one-pagers, sales decks, reports, use cases, and assessments to execute on websites, customer portals/community, and technical sites.' },
          { id: 3, text: 'Report on product marketing metrics and goals, including product launches.' },
          { id: 4, text: 'Define the go-to-market strategy for your product portfolio.' },
          { id: 5, text: 'Communicate product releases internally and externally.' },
          { id: 6, text: 'Stay up to date on industry trends and market developments and use this knowledge to inform marketing strategies and tactics of KDI.' },
          { id: 7, text: 'Develop a thorough understanding of the business priorities and translate this into the end-to-end orchestration of annual and quarterly marketing plans.' },
          { id: 8, text: 'Define the go-to-market strategy for your product portfolio.' },
          { id: 9, text: 'Equip sales teams with the right message, audience insight, and product development information.' },
          { id: 10, text: 'Build creditability in the analyst community.' },
          { id: 11, text: 'Create outputs for Sales and Marketing.' }
        ]
      }
    }, 
];
}
