import { Component } from '@angular/core';

@Component({
  selector: 'pet-key-principles',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class KeyPrinciplesComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'The ADAPT Framework is built on a strong foundation of principles that drive its success. These principles ensure teams remain focused on delivering value, fostering collaboration, and maintaining alignment with strategic objectives.'
      }
    },   
    {
      type: 'multibulletList',
      value: {
        title: 'The Five Key Principles of ADAPT',
        items: [
          {
            name: 'Customer and Market-Centricity',
            description: [
              'Deeply understanding customer needs and market dynamics.',
              'Prioritizing initiatives that address real customer pain points.'
            ]
          },
          {
            name: 'Cross-Functional Collaboration',
            description: [
              'Encouraging open communication and shared accountability across teams.',
              'Aligning product managers, designers, developers, and other stakeholders to deliver cohesive solutions.'
            ]
          },
          {
            name: 'Accountability',
            description: [
              'Empowering individuals and teams to take ownership of their roles and outcomes.',
              'Encouraging proactive problem-solving and innovation.'
            ]
          },
          {
            name: 'End-to-End Responsibility',
            description: [
              'Teams own the entire product lifecycle—from discovery to deployment and scaling.',
              'Provide feedback.',
              'Reduces handoffs and delays, ensuring seamless delivery.'
            ]
          },
          {
            name: 'Adaptability',
            description: [
              'Embracing change as an opportunity to improve.',
              'Iteratively refining processes to align with evolving priorities and market demands.'
            ]
          }
        ]
      }
    },
    {
      type: 'intro',
      value: {
        mainText: 'Why These Principles are Important',
        subText: 'These principles ensure that ADAPT remains flexible yet robust, capable of handling the complexities of modern product development. They also instill a culture of accountability, innovation, and continuous improvement.'
      }
    },    
];
}
