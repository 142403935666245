import { Component } from '@angular/core';

@Component({
  selector: 'pet-development-team',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class DevelopmentTeamComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Role Definition',
        subText:'The Development Team (dev team) consists of professionals (usually developers, QA, Product Designer and others) who regularly deliver working software. Development teams are autonomous, and promoted to become empowered.'
      }
    },     
    {
      type: 'checkListItem',
      value: {
        title: 'Prerequisites',
        items: [
          { id: 1, text: 'A software product.' },
          { id: 2, text: 'Follows Development Team Mandates.' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Responsibilities',
        items: [
          { id: 1, text: 'Organize the team to be efficient.' },
          { id: 2, text: 'Ensure they have all the necessary skills to deliver working software.' },
          { id: 3, text: 'Share responsibility of the final product from start to finish.' }
        ]
      }
    }, 
    {
      type: 'multibulletList',
      value: {
        title: 'Development Team Mandates (aka Contract)',
        items: [
          {
            name: 'Mandate 1: Deliver working software',
            description: [
              'Teams are expected to deliver a new “deployable” version at least every 3 weeks (per sprint). A "deployable" version or increment refers to a version of the product that is functionally complete, thoroughly tested, and potentially shippable. It means that the software is in a state where it could be released to users if needed. The decision to actually deploy it in the production environment is discretionary based on various factors such as business requirements, market strategy, customer feedback cycles, and regulatory compliance.',
              'Teams are expected to be able to deploy their deliverables at any point in time (deploy on demand).',
              'When significant bugs are found, teams are expected to fix them quickly based on business priorities and bug severity.'
            ]
          },
          {
            name: 'Mandate 2: Deliver User Value',
            description: [
              'It is up to the teams to define, explore and decide user value based on valid outcome driven process i.e. discovery and customer validation, for example.',
              'At any point in time, teams are expected to work on whatever gives the most user value. This means the team’s prioritized backlog must match expected user value. Some features may give short term user value, while technical debt reduction may only give user value in the long term. Prioritizations must balance such needs. The order of the backlog must thus be justifiable in terms of perceived user value.'
            ]
          },
          {
            name: 'Mandate 3: Collaborate',
            description: [
              'Teams are expected to regularly demonstrate new versions.',
              'Teams are expected to have contact persons available for others to discuss value, priorities, technology and similar.',
              'The team must make clear what it is responsible for. Who are in the team, what value stream and user needs, which applications and technologies.',
              'The team must take full responsibility for its area. This includes security, quality, risk, and others.'
            ]
          },
          {
            name: 'Mandate 4: Transparency',
            description: [
              'The team’s future plans are expected to be available to all, in a prioritized order – a backlog.',
              'Items in the backlog are expected to have a maximum size of 2-3 weeks before a team starts work on it.',
              'The team’s current ongoing work is expected to be available to all – a sprint overview.',
              'The team is expected to only pick work from the top of the backlog.',
              'Work and code are expected to be available to anyone interested (e.g. InnerSourcing based on access rights).'
            ]
          },
          {
            name: 'Mandate 5: Exceptions',
            description: [
              'There will be exceptions. However, teams should strive to minimize exceptions to this mandate contract.',
              'All exceptions should be broadly communicated to relevant stakeholders.'
            ]
          },
          {
            name: 'Mandate 6: Rights',
            description: [
              'The team may mandate scope freezes when it starts work. A freeze might be for the scope of a single sprint. Or if a feature cannot be finished in one sprint, the feature might be frozen in the backlog to finish it in the subsequent sprint. A freeze may at most last a few weeks.',
              'If the team does not understand what it is supposed to achieve, it may demand more discussions before starting work. Only the team knows when it understands something, so it may set its own rules for what is needed to clarify work.'
            ]
          }
        ]
      }
    },   
];
}
