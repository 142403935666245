import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-product-portfolio-strategy',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ProductPortfolioStrategyComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The Portfolio & Product Strategy process focuses on developing comprehensive product and portfolio strategies that align with organizational objectives. It involves creating investment plans, updating management and stakeholders, and ensuring that portfolio positioning, strategic roadmaps, and release plans reflect market needs and business goals. This process bridges strategic vision and tactical execution by aligning resources, priorities, and timelines across the product portfolio.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Chief Product Officer (CPO)",
    "description": "Approves the overall portfolio and product strategy, ensuring alignment with corporate objectives."
  },
  {
    "name": "Chief Strategy Officer (CSO)",
    "description": "Responsible for driving the portfolio assessment and investment prioritization process."
  },
  {
    "name": "Chief Technology Officer (CTO)",
    "description": "Supports alignment of technological roadmaps with strategic priorities."
  },
  {
    "name": "Customer Success and Growth Teams",
    "description": "Provide insights and feedback to ensure strategies align with customer and market needs."
  },
  {
    "name": "Finance Department",
    "description": "Ensures investment plans are feasible and aligned with budgetary constraints."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Portfolio Assessment and Segmentation",
            "description": {
              "Objective": [
                "Evaluate the existing product portfolio and identify market segments for focus."
              ],
              "Activity": [
                "Conduct portfolio assessments to evaluate market positioning, financial performance, and strategic fit.",
                "Use macro segmentation techniques to categorize and prioritize opportunities across markets and customer types."
              ],
              "Outcome": [
                "A clear understanding of portfolio strengths, gaps, and opportunities."
              ]
            }
          },
          {
            "title": "Strategic Roadmap and Alignment",
            "description": {
              "Objective": [
                "Develop strategic roadmaps that align portfolio priorities with corporate objectives."
              ],
              "Activity": [
                "Collaborate with product teams to outline high-level milestones and investment priorities.",
                "Ensure alignment with corporate strategy and integrate feedback from stakeholders in technology, customer success, and growth teams."
              ],
              "Outcome": [
                "An aligned strategic roadmap that guides resource allocation and prioritization."
              ]
            }
          },
          {
            "title": "Stakeholder Management and Communication",
            "description": {
              "Objective": [
                "Engage stakeholders to ensure transparency and buy-in for portfolio strategies."
              ],
              "Activity": [
                "Regularly update management and teams on portfolio positioning, strategic plans, and progress.",
                "Facilitate workshops and presentations to gather feedback and refine strategies."
              ],
              "Outcome": [
                "Stakeholder alignment and shared ownership of portfolio and product strategies."
              ]
            }
          },
          {
            "title": "Release Planning and Execution",
            "description": {
              "Objective": [
                "Translate strategic roadmaps into actionable release plans."
              ],
              "Activity": [
                "Identify release timelines and interdependencies across the product portfolio.",
                "Work with operational teams to plan and execute releases effectively."
              ],
              "Outcome": [
                "Coordinated release plans that deliver value while minimizing risks."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Artifacts and Deliverables',
        items:[
          {title:'Portfolio Assessment Report', description: 'Detailed analysis of product portfolio performance and market positioning.'
          },
          {title:'Strategic Roadmaps', description: 'High-level plans outlining milestones, timelines, and priorities.'
          },
          {title:'Aligned Product Plans', description: 'Comprehensive product plans using standardized templates.'
          },
          {title:'Release Plans', description: 'Synchronized schedules and actions for successful product rollouts.'
          }
        ]
      }
    }
  ];
}