import { Component } from '@angular/core';

@Component({
  selector: 'pet-tech-lead',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class TechLeadComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Role Definition',
        subText:'The Tech Lead role at KDI isn’t always a fixed title but should be present in spirit to ensure that technical insights and decisions are integrated into the product lifecycle from the earliest stages. The Tech Lead ensures high-quality, technically sound products through close collaboration with the Product Manager (PM) and Product Designer (PD). This role drives feasibility, mentors the engineering team, and guides architecture to align with strategic goals, fostering a culture of best practices and continuous improvement. In smaller teams, these responsibilities may be shared by the development team, while larger teams may require a dedicated Tech Lead.'
      }
    },     
    {
      type: 'checkListItem',
      value: {
        title: 'Prerequisites',
        items: [
          { id: 1, text: 'Strong Technical Expertise: Proficiency in relevant technologies, architecture, and engineering best practices, with a proven ability to make sound technical decisions.' },
          { id: 2, text: 'Experience in Product Development: Prior experience working in cross-functional product teams, ideally in an Agile or empowered team environment, with a focus on delivering customer-centered solutions.' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Deliverables',
        items: [
          { id: 1, text: 'Technical Vision and Feasibility' },
          { id: 2, text: 'Collaboration in Discovery' },
          { id: 3, text: 'Technical Authority, Not People Management' },
          { id: 4, text: 'Prioritized and ordered Product Backlog' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Responsibilities',
        items: [
          { id: 1, text: 'Collaborate closely with Product Manager and Product Designer in discovery, ensuring early technical insights shape feasible, impactful solutions.' },
          { id: 2, text: 'Guide architectural decisions and set quality standards to support scalable and maintainable product development.' },
          { id: 3, text: 'Ensures that technology plays a key role in the creation of innovative solutions based on customer insights.' },
          { id: 4, text: 'Act as a mentor within the engineering team, sharing best practices and fostering a culture of technical excellence.' },
          { id: 5, text: 'Adapt role responsibilities based on team needs, remaining flexible to provide the right level of technical leadership and support.' }
        ]
      }
    }, 
];
}
