import { Component } from '@angular/core';

@Component({
  selector: 'pet-product-backlog-refinement',
  template: 
  `
  <p>
      Home > ADAPT Lifecycle Stages > Roadmap & Planning > Product Backlog Refinement
  </p>
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ProductBacklogRefinementComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'Product Backlog Refinement (also known as Grooming) is an ongoing process in the Roadmap & Planning stage of the ADAPT framework. The objective is to ensure backlog items are well-prepared, prioritized, and aligned with business goals before entering sprint planning. It involves breaking down larger features into manageable work, estimating effort, and identifying dependencies and risks. This process is critical for keeping the backlog relevant and actionable while enabling seamless sprint execution.'
      }
    },
    {
      type:'listItem',
      value:{
        title:'Roles and Responsibilities',
        items:[
          {name:'Product Manager', description:'Owns the backlog, provides strategic input on the priority and value of features or epics, ensuring alignment with the overall roadmap.'},
          {name:'Product Manager/Owner*', description:'Clarifies backlog items, sets priorities, and ensures alignment with strategic goals.'},
          {name:'Tech Lead', description:'Assesses technical feasibility, identifies dependencies, and highlights potential risks.'},
          {name:'Product Designer', description:'Provides design insights and ensures user experience considerations are addressed.'}
        ]
      }
    },
    {
      type: 'superListItem',
      value: {
        title: 'Process Steps',
        items: [
          {
            title: 'Prioritization',
            description: {
              'Objective': [
                'Reorder backlog items based on business value, effort, risk, and urgency.'
              ],
              'Activity': [
                'The Product Owner uses a consistent prioritization method (e.g., WSJF, RICE).',
                'Align items with strategic objectives while considering stakeholder input.'
              ],
              'Outcome': [
                'A prioritized backlog that reflects business needs and strategic goals.'
              ]
            }
          },
          {
            title: 'Clarification',
            description: {
              'Objective': [
                'Ensure backlog items are clear and actionable for the development team.'
              ],
              'Activity': [
                'The Product Owner and key stakeholders refine backlog items to include clear descriptions, acceptance criteria, and necessary supporting details.',
                'Address outstanding questions and ensure user stories are ready for planning.'
              ],
              'Outcome': [
                'Refined backlog items with clear requirements and acceptance criteria.'
              ]
            }
          },
          {
            title: 'Breakdown of Epics',
            description: {
              'Objective': [
                'Decompose larger epics into smaller, actionable user stories.'
              ],
              'Activity': [
                'Break down epics into manageable stories that can be delivered within a sprint.',
                'Ensure alignment with team capacity and sprint planning requirements.'
              ],
              'Outcome': [
                'Actionable user stories derived from high-level epics.'
              ]
            }
          },
          {
            title: 'Two-Stage Refinement',
            description: {
              'Objective': [
                'Refine backlog items iteratively for readiness in the upcoming sprint.'
              ],
              'Activity': [
                'First Refinement (Week 1):',
                '󠀠󠀠󠁝― Review and prioritize items, ensuring they are well-defined.',
                '― Break down epics, clarify acceptance criteria, and flag high-risk items.',
                'Second Refinement (Week 2):',
                '― Incorporate feedback and reassess items based on updated insights.',
                '― Finalize readiness for sprint planning by resolving outstanding details.'
              ],
              'Outcome': [
                'A refined and prioritized backlog, ready for sprint planning.'
              ]
            }
          },
          {
            title: 'Effort Estimation',
            description: {
              'Objective': [
                'Estimate the effort required for each backlog item to guide planning.'
              ],
              'Activity': [
                'Use techniques like Planning Poker, T-shirt sizing, or relative estimation.',
                'Adjust estimates based on additional information or stakeholder feedback.'
              ],
              'Outcome': [
                'Accurate effort estimates for user stories, enabling realistic planning.'
              ]
            }
          },
          {
            title: 'Dependency Identification & Risk Management',
            description: {
              'Objective': [
                'Proactively identify and address dependencies and risks.'
              ],
              'Activity': [
                'The Tech Lead identifies technical dependencies and potential blockers.',
                'Use a risk assessment matrix to categorize and mitigate high-risk items.'
              ],
              'Outcome': [
                'A risk-mitigated and dependency-aware backlog.'
              ]
            }
          },
          {
            title: 'Feedback Loops & Continuous Improvement',
            description: {
              'Objective': [
                'Incorporate lessons learned to enhance backlog refinement.'
              ],
              'Activity': [
                'Integrate feedback from sprint reviews and stakeholders.',
                'Regularly update the backlog to align with strategic goals and priorities.'
              ],
              'Outcome': [
                'An improved backlog refinement process that remains aligned with evolving business needs.'
              ]
            }
          }
        ]
      }
    },
{type:'listItem',
  value:{
    title:'Practical Examples',
    items:[
      {name:'Prioritization Example:', description:'Use WSJF to prioritize a feature that drives high business value but has moderate implementation risk.'},
      {name:'Risk Management Example:', description:'For a feature dependent on an external API, the team conducts an early risk assessment to mitigate potential integration issues.'}
      
    ]
  }
},
{
  type:'checkListItem',
  value:{
    title:'Tools / Resources / Templates',
    items:[
      {id:1, text:'Azure DevOps: Utilize features for backlog management, risk tracking, and estimation.'},
      {id:2, text:'Risk Assessment Template: A downloadable template for identifying and managing risks.'},
      {id:3, text:'Prioritization Framework Guide: A reference to WSJF and RICE (link to the “Information & Support” section).'}
    ]
  }
},
];
}
