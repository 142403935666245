import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-work-estimation',
  template: `
  <hr>  
  <h2>
      Work Estimation
    </h2>
    <div style="text-align: right;">
      <button class="btn btn-secondary" (click)="goBack()">
        <i class="fa fa-arrow-left"></i> Back
      </button>
    </div>
      <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})
export class WorkEstimationComponent {
  public goBack() {
    window.history.back();
  }
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The activity of providing detailed estimates for well-defined user stories from the refined backlog and allocating them to the team for upcoming sprints, ensuring the delivery of business value alongside technical and operational considerations. The best practices for work estimation, ensuring alignment with sprint outcomes and ADAPT’s outcome-driven philosophy.'
      }
    },
    {
      type:'listItem',
      value:{
        title:'Prerequisites',
        items:[
          {name:'A refined backlog with clearly defined user stories', description:'Backlog items should be actionable and broken down into manageable pieces to facilitate estimation and sprint planning. Each story shall adhere to a structure, like, INVEST principles, for example. This ensures clarity and alignment with business outcomes.'},
          {name:'Team members available for backlog refinement and sprint planning', description:'Participation from the entire team, including the product owner, technical leads, and developers, is essential to ensure shared understanding and accurate estimations. Their availability during refinement and planning sessions helps address dependencies and clarify uncertainties.'},
          {name:'An understanding of team capacity (availability, holidays, and other factors)', description:'Knowing the team\'s capacity is critical for realistic sprint planning. Account for holidays, part-time contributors, and any other constraints that might impact the team\'s ability to deliver within the sprint timeframe.'}
        ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Best Practices for Work Breakdown",
        "items": [
          {
            "title": "Backlog Refinement",
            "description": {
              "Objective": [
                "Collaboratively refine backlog items to ensure they are clear, actionable, and aligned with business goals."
              ],
              "Activity": [
                "Break epics into smaller user stories, ensuring they are achievable within a sprint.",
                "Use techniques like story splitting and acceptance criteria workshops.",
                "Prioritize items based on business value, technical feasibility, and dependencies."
              ],
              "Outcome": [
                "Well-defined and prioritized backlog items ready for sprint planning."
              ],
              "Example": [
                "Epic: Implement a new login system.",
                "Split into smaller stories:",
                "1. Set up authentication service (5 story points).",
                "2. Create frontend login UI (3 story points).",
                "3. Integrate with user database (5 story points)."
              ]
            }
          },
          {
            "title": "Estimation",
            "description": {
              "Objective": [
                "Choose an estimation method that works for your team and estimate each user story collaboratively."
              ],
              "Activity": [
                "Use Planning Poker for story points to build consensus.",
                "If using effort-based estimation, account for risks and unknowns."
              ],
              "Outcome": [
                "Realistic and agreed-upon effort estimates for backlog items."
              ],
              "Example": [
                "Story: 'Set up authentication service.'",
                "Estimated as 5 story points based on moderate complexity and low risk."
              ]
            }
          },
          {
            "title": "Sprint Planning",
            "description": {
              "Objective": [
                "Match refined backlog items to team capacity and align on a sprint goal that reflects the highest priority business outcomes."
              ],
              "Activity": [
                "Use velocity trends to ensure realistic sprint commitments.",
                "Account for dependencies between user stories when allocating them."
              ],
              "Outcome": [
                "A sprint backlog aligned with team capacity and business priorities."
              ],
              "Example": [
                "Team capacity: 30 story points.",
                "Sprint backlog: 6 stories (3 points, 5 points, 8 points, etc.) aligned with a goal of completing the login system."
              ]
            }
          },
          {
            "title": "Continuous Improvement",
            "description": {
              "Objective": [
                "Review estimation and sprint planning during retrospectives to identify and implement process improvements."
              ],
              "Activity": [
                "Track velocity trends and throughput to improve predictability.",
                "Experiment with new tools or techniques to enhance efficiency."
              ],
              "Outcome": [
                "Refined estimation techniques and improved sprint planning accuracy."
              ],
              "Example": [
                "Retrospective finding: Overestimated complexity of story 'Create frontend login UI.'",
                "Next step: Use smaller reference stories for future estimation."
              ]
            }
          }
        ]
      }
    },  
    {type:'attachment',
      value:{
        title:'Media/Attachments',
        items:[
          {src:'assets/media/images/BP-Estimation.png', description:'Work Estimation & Planning'}
        ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Example: Implement Secure Login Functionality",
        "items": [
          {
            "title": "Epic Details",
            "description": {
              "Details": [
                "Epic Name: Implement Secure Login Functionality",
                "T-Shirt Size Estimation: Large (L)",
                "Rationale: Involves multiple subsystems (authentication service, frontend UI, backend integration) and potential third-party dependencies.",
                "Acceptance Criteria:",
                "- Users can log in with valid credentials.",
                "- Invalid login attempts show an error message.",
                "- Passwords are securely encrypted.",
                "- Audit logs capture login attempts."
              ]
            }
          },
          {
            "title": "Sprint Goal",
            "description": {
              "Example": [
                "Goal: Complete the login system to enable secure user access.",
                "User Stories for this Sprint:",
                "1. Set up authentication service (8 points).",
                "2. Create frontend login UI (5 points).",
                "3. Integrate with user database (8 points)."
              ]
            }
          },
          {
            "title": "User Story 1: Set Up Authentication Service",
            "description": {
              "Details": [
                "Title: Set up authentication service to validate user credentials.",
                "Story Points: 8 points (high complexity due to backend dependencies).",
                "Acceptance Criteria:",
                "- The system validates credentials against the user database.",
                "- Invalid credentials return appropriate error codes.",
                "- Passwords are encrypted using industry-standard algorithms.",
                "Tasks:",
                "- Research and implement encryption library (5 hours).",
                "- Develop API for user authentication (10 hours).",
                "- Unit tests for authentication logic (3 hours)."
              ]
            }
          },
          {
            "title": "User Story 2: Create Frontend Login UI",
            "description": {
              "Details": [
                "Title: Develop login screen with username and password fields.",
                "Story Points: 5 points (medium complexity due to UI design).",
                "Acceptance Criteria:",
                "- Login form includes fields for username and password.",
                "- Error messages are displayed for invalid input or login failures.",
                "- Form submission integrates with the backend API.",
                "Tasks:",
                "- Design UI mockup for the login screen (3 hours).",
                "- Implement frontend components (5 hours).",
                "- Write unit tests for UI validation (2 hours)."
              ]
            }
          },
          {
            "title": "User Story 3: Integrate with User Database",
            "description": {
              "Details": [
                "Title: Connect authentication service to the user database.",
                "Story Points: 8 points (high complexity due to database interactions).",
                "Acceptance Criteria:",
                "- Backend connects to the user database securely.",
                "- Supports CRUD operations for user data.",
                "- Logs successful and failed login attempts.",
                "Tasks:",
                "- Set up database schema for user records (5 hours).",
                "- Implement database access logic (8 hours).",
                "- Validate database connection and queries (3 hours)."
              ]
            }
          }
        ]
      }
    }
    ,
    {
      type: 'faq',
      value: {
        heading: 'FAQs: Story Points, Capacity, and Progress Tracking in Agile Teams',
        questions: [
          {
            question: 'How to burn story points if some part of the task is completed and some is spilled over?',
            answer: `
              <p><strong>Best Practice:</strong> Story points are assigned to the entire user story and are only burned when the story meets the definition of done (DoD).</p>
              <p><strong>Spillover Handling:</strong></p>
              <ul>
                <li>If part of the work is completed, break the remaining spillover into a new user story for the next sprint. Assign new story points based on the remaining complexity.</li>
                <li>Avoid partially burning story points in the current sprint to maintain the integrity of velocity tracking.</li>
              </ul>
              <p><strong>Example:</strong></p>
              <ul>
                <li>Original Story: "Implement authentication service" (8 story points).</li>
                <li>Work Completed in Sprint: Backend API development (majority done).</li>
                <li>Spillover: Remaining tests and bug fixes.</li>
                <li>New Story for Next Sprint: "Complete authentication service testing" (assign 2 points if the remaining work is minimal).</li>
              </ul>
            `
          },
          {
            question: 'Capacity is in hours. How to relate capacity with story points and show developer capacity in a sprint?',
            answer: `
              <p><strong>Capacity and Story Points Relationship:</strong></p>
              <ul>
                <li>While story points measure complexity, capacity in hours tracks effort. There’s no fixed conversion between the two.</li>
                <li>Use historical velocity (average story points completed per sprint) to estimate how many story points the team can handle relative to their available hours.</li>
                <li>For capacity planning, account for team availability (holidays, partial allocations) to calculate available hours.</li>
              </ul>
              <p><strong>Per-Developer Capacity:</strong></p>
              <ul>
                <li>Allocate total sprint capacity across developers based on their availability. For example:
                  <ul>
                    <li>Total capacity = 160 hours.</li>
                    <li>Developer A = 40 hours, Developer B = 30 hours, etc.</li>
                  </ul>
                </li>
                <li>Use these capacities to assign tasks under user stories and ensure alignment with sprint goals.</li>
              </ul>
              <p><strong>Tip:</strong> Avoid rigid mapping of story points to hours; focus on team-level capacity and velocity trends.</p>
            `
          },
          {
            question: 'What does 1 story point mean for an experienced developer versus a fresher?',
            answer: `
              <p><strong>Uniform Definition:</strong></p>
              <ul>
                <li>Story points reflect relative complexity, effort, and risk, not the time taken or individual skill level.</li>
                <li>Use a reference story as a baseline to calibrate the team's understanding. For example:
                  <ul>
                    <li>"Login UI development" = 3 story points.</li>
                    <li>Other stories are estimated relative to this.</li>
                  </ul>
                </li>
              </ul>
              <p><strong>Team Alignment:</strong></p>
              <ul>
                <li>Encourage the team to estimate collectively during refinement sessions (e.g., Planning Poker).</li>
                <li>Over time, freshers will align their perspective with the team’s shared understanding.</li>
              </ul>
              <p><strong>Key Principle:</strong> Story points are a team measure, not an individual one.</p>
            `
          },
          {
            question: 'What is Planning Poker, and how can it resolve disagreements in estimation?',
            answer: `
              <p><strong>Purpose:</strong> Planning Poker is a collaborative estimation technique used to assign story points to user stories. It ensures all team members contribute their perspectives on complexity, effort, and risk.</p>
              <p><strong>Practical Usage:</strong></p>
              <ul>
                <li>Each team member independently selects a card (e.g., Fibonacci numbers) to estimate the story's complexity.</li>
                <li>All estimates are revealed simultaneously to avoid bias.</li>
                <li>If there is a significant difference in estimates, team members discuss their reasoning.</li>
              </ul>
              <p><strong>Example:</strong></p>
              <ul>
                <li>Scenario: A senior architect estimates 3 points, while a junior resource estimates 8 points.</li>
                <li>Resolution:
                  <ul>
                    <li>The junior resource explains their concerns (e.g., lack of clarity in requirements, perceived technical risks).</li>
                    <li>The senior architect addresses these concerns by sharing context (e.g., reusable components, existing patterns).</li>
                    <li>The team revises the estimate collectively, ensuring everyone understands the rationale behind the final estimate (e.g., settling on 5 points).</li>
                  </ul>
                </li>
              </ul>
              <p><strong>Key Principle:</strong> The goal is to achieve consensus and shared understanding, leveraging diverse perspectives to produce accurate, team-aligned estimates.</p>
            `
          },
          {
            question: 'How to track progress if team members don’t create tasks under user stories or do daily burndowns?',
            answer: `
              <p><strong>Encourage Task Creation:</strong></p>
              <ul>
                <li>Break user stories into smaller tasks for better progress tracking and visibility. Tasks help identify bottlenecks and distribute work evenly.</li>
              </ul>
              <p><strong>Alternative Tracking:</strong></p>
              <ul>
                <li>Use a Kanban board to track the movement of user stories through stages (To Do → In Progress → Done).</li>
                <li>Conduct daily standups to discuss progress at the story level and address blockers.</li>
              </ul>
              <p><strong>Best Practice:</strong> While daily burndowns are ideal, a team can use cumulative flow diagrams (CFD) or track work-in-progress (WIP) limits to monitor progress visually.</p>
            `
          },
          {
            question: 'Does story point estimation differ based on team experience levels?',
            answer: `
              <p><strong>Short Answer:</strong> No, story points are team-relative, not person-relative.</p>
              <p><strong>Why:</strong></p>
              <ul>
                <li>Story points reflect complexity, effort, and risk perceived by the team collectively, irrespective of individual experience.</li>
                <li>Use Planning Poker or similar collaborative estimation techniques to normalize perspectives.</li>
              </ul>
              <p><strong>Team Alignment:</strong></p>
              <ul>
                <li>Over time, freshers will adjust their perception of complexity to align with experienced members.</li>
                <li>Regular retrospective discussions can refine how the team collectively estimates stories.</li>
              </ul>
            `
          },
          {
            question: 'What is the standard definition of story points, capacity planning, hours, burn down, and their significance?',
            answer: `
              <p><strong>Story Points:</strong></p>
              <ul>
                <li>Definition: Measure of relative complexity, effort, and risk for a user story.</li>
                <li>Significance: Helps estimate team velocity and plan sprints without tying directly to hours.</li>
              </ul>
              <p><strong>Capacity Planning:</strong></p>
              <ul>
                <li>Definition: Determining the team’s total available working hours for a sprint.</li>
                <li>Significance: Ensures sprint commitments are realistic based on team availability.</li>
              </ul>
              <p><strong>Hours:</strong></p>
              <ul>
                <li>Definition: Actual time allocated to individual tasks under user stories.</li>
                <li>Significance: Tracks granular effort for capacity planning but isn’t directly tied to story points.</li>
              </ul>
              <p><strong>Burn Down:</strong></p>
              <ul>
                <li>Definition: Chart showing the amount of work remaining versus time in the sprint.</li>
                <li>Significance: Tracks sprint progress and highlights whether the team is on track to meet goals.</li>
              </ul>
            `
          }
        ]
      }
    },
    {
      type: 'faq',
      value: {
        heading: 'Work Estimation and Velocity FAQs',
        questions: [
          {
            question: 'What does it mean if a user story is estimated with Planning Poker and receives 8 story points?',
            answer: `
              <p><strong>What this means:</strong> The team collectively estimated the relative complexity of the user story as 8 story points. This indicates it’s more complex than smaller stories (e.g., 3 points) but not as complex as larger ones (e.g., 13 points).</p>
              <p><strong>How to proceed:</strong></p>
              <ul>
                <li>Break the user story into smaller tasks for better visibility (recommended).</li>
                <li>Assign story points to track velocity but avoid mapping story points directly to time (e.g., hours).</li>
              </ul>
            `
          },
          {
            question: 'Should I create tasks under user stories, and what happens if I don’t?',
            answer: `
              <p><strong>Best Practice:</strong> Always create tasks under user stories in Azure DevOps.</p>
              <ul>
                <li>Tasks provide clarity on the granular work required to complete the user story.</li>
                <li>They help allocate hours for capacity tracking and facilitate daily progress updates.</li>
              </ul>
              <p><strong>If you skip tasks:</strong></p>
              <ul>
                <li>Monitor the progress of the user story itself, but it will be harder to pinpoint specific blockers or distribute work across team members.</li>
              </ul>
            `
          },
          {
            question: 'How do I align team capacity (e.g., 160 hours) with sprint planning?',
            answer: `
              <p><strong>How to use capacity:</strong></p>
              <ul>
                <li>Break tasks under user stories and assign estimated hours to each task.</li>
                <li>Ensure that the total task effort for the sprint aligns with the team’s 160-hour capacity.</li>
              </ul>
              <p><strong>Example:</strong></p>
              <ul>
                <li>Team capacity = 160 hours.</li>
                <li>Tasks under user stories:
                  <ul>
                    <li>Task 1: 8 hours.</li>
                    <li>Task 2: 12 hours.</li>
                    <li>Task 3: 15 hours.</li>
                  </ul>
                </li>
              </ul>
              <p>Continue assigning tasks until you approach ~80–90% of team capacity (to account for unplanned work).</p>
            `
          },
          {
            question: 'How can I plan if I have no idea about my team’s velocity?',
            answer: `
              <p><strong>What to do:</strong></p>
              <ul>
                <li>If this is the first sprint, use capacity in hours to approximate what the team can handle.</li>
                <li>After the sprint, calculate velocity:
                  <ul>
                    <li>Velocity = Total story points completed in the sprint.</li>
                  </ul>
                </li>
                <li>Use this as a baseline for future sprints.</li>
              </ul>
              <p><strong>Tip:</strong> Velocity stabilizes over 3–4 sprints. Track it to improve planning accuracy.</p>
            `
          },
          {
            question: 'How can I estimate how much time is needed to complete a user story?',
            answer: `
              <p><strong>Using Tasks:</strong></p>
              <ul>
                <li>Break the user story into tasks and estimate hours for each task.</li>
                <li>Sum up the hours to estimate the total time required for the story.</li>
              </ul>
              <p><strong>Without Tasks:</strong></p>
              <ul>
                <li>If no tasks are created, use past sprints to infer how much time the team typically spends on stories of similar complexity (e.g., 8 story points).</li>
              </ul>
            `
          },
          {
            question: 'What should I do if a user story spills over to the next sprint?',
            answer: `
              <p><strong>Best Practice for Spillover:</strong></p>
              <ul>
                <li><strong>Option 1:</strong> Retain the original user story in the next sprint and mark it as "In Progress."
                  <ul>
                    <li>Use a comment or a field in Azure DevOps to indicate that part of the work was completed in the previous sprint.</li>
                  </ul>
                </li>
                <li><strong>Option 2:</strong> Create a new user story for the remaining work (recommended if the remaining work is small and distinct). Link it to the original story using the "Related Work" feature in Azure DevOps.</li>
              </ul>
              <p><strong>Tip:</strong> Ensure retrospectives address why the spillover happened to improve future planning.</p>
            `
          },
          {
            question: 'Should I create a new user story for spillover work, or carry over the original one?',
            answer: `
              <p><strong>When to create a new user story:</strong></p>
              <ul>
                <li>If the remaining work is distinct or minimal, create a new user story for clarity and assign appropriate story points (e.g., 2 points for remaining tests).</li>
                <li>Link the new story to the original one using Azure DevOps links (e.g., "Parent-Child" or "Related").</li>
              </ul>
              <p><strong>When not to create a new user story:</strong></p>
              <ul>
                <li>If the original story is partially completed and the remaining work is integral to the same story, carry it over as-is.</li>
              </ul>
            `
          },
          {
            question: 'How do I handle story point complexity for partially completed user stories?',
            answer: `
              <p><strong>Keep the Original Story Points Intact:</strong> Story points represent the total complexity of the story. Don’t adjust them, even if part of the work spills over.</p>
              <p><strong>Track Remaining Work:</strong></p>
              <ul>
                <li>Use Azure DevOps to:
                  <ul>
                    <li>Mark completed tasks within the story.</li>
                    <li>Create a new remaining work estimate (in hours or percentage) for visibility.</li>
                  </ul>
                </li>
              </ul>
              <p><strong>Example:</strong></p>
              <ul>
                <li>Story: "Set up authentication service" (8 story points).</li>
                <li>Work Completed: Backend logic (70%).</li>
                <li>Remaining Work: Testing and bug fixes.</li>
                <li><strong>Option 1:</strong> Add comments or tags indicating 30% work remains.</li>
                <li><strong>Option 2:</strong> Create a linked user story for remaining tasks.</li>
              </ul>
            `
          },
          {
            question: 'But estimation is an unnecessary overhead, isn\'t it?',
            answer: `
              <p><strong>Why Estimates Matter:</strong></p>
              <ul>
                <li><strong>Purpose:</strong> Estimates aren’t about precision; they provide a shared understanding of complexity and help with planning and prioritization.</li>
                <li><strong>Value:</strong> They allow the team to:
                  <ul>
                    <li>Set realistic sprint goals.</li>
                    <li>Track progress over time (via velocity).</li>
                    <li>Improve predictability for stakeholders.</li>
                  </ul>
                </li>
              </ul>
              <p><strong>How to Reduce Overhead:</strong></p>
              <ul>
                <li>Use lightweight estimation techniques (e.g., Planning Poker or T-shirt sizing).</li>
                <li>Focus on relative estimates, avoiding detailed time-based effort discussions during initial estimation.</li>
              </ul>
            `
          },
          {
            question: 'So, why is velocity important in addition to task hour estimates?',
            answer: `
              <p><strong>Purpose:</strong> Velocity helps predict how much work (story points) a team can complete in a sprint.</p>
              <p><strong>Team-Level Metric:</strong> Velocity is a macro-level measure of the team's capability over time. It allows you to:</p>
              <ul>
                <li>Plan future sprints with confidence.</li>
                <li>Forecast long-term delivery timelines.</li>
                <li>Identify trends or bottlenecks in team productivity.</li>
              </ul>
            `
          },
          {
            question: 'Why do I need task hour estimates when I already track velocity?',
            answer: `
              <p><strong>Purpose:</strong> Tasks with hour-based estimates provide micro-level visibility into daily work progress and capacity.</p>
              <p><strong>How They Complement Each Other:</strong></p>
              <ul>
                <li><strong>Velocity:</strong> Supports sprint-level planning.</li>
                <li><strong>Task hours:</strong> Track daily execution and ensure alignment with team capacity.</li>
              </ul>
              <p><strong>Example:</strong></p>
              <ul>
                <li>User Story (8 points):
                  <ul>
                    <li>Task 1: 8 hours.</li>
                    <li>Task 2: 5 hours.</li>
                    <li>Task 3: 10 hours.</li>
                    <li>Total = 23 hours.</li>
                  </ul>
                </li>
              </ul>
            `
          },
          {
            question: 'Is it double work to estimate both story points and task hours?',
            answer: `
              <p><strong>Not if used wisely:</strong></p>
              <ul>
                <li><strong>Story Points:</strong> High-level, team-based planning.</li>
                <li><strong>Task Hours:</strong> Detailed, individual-level execution.</li>
              </ul>
              <p><strong>How They Work Together:</strong></p>
              <ul>
                <li>Story points enable long-term planning (e.g., velocity tracking).</li>
                <li>Task hours ensure daily progress aligns with capacity.</li>
              </ul>
            `
          }
        ]
      }
    }
    
    
                 
  ];
}
