import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-development',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class DevelopmentComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The Development activity within the ADAPT framework integrates secure, agile, and collaborative practices to deliver high-quality software. By incorporating DevSecOps principles, the process ensures that security is prioritized from the early stages of development through deployment. This approach focuses on proactive threat identification, continuous validation, and adherence to coding standards while leveraging automated tools and collaborative techniques.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Development Team",
    "description": "Write secure, functional, and high-quality code while collaborating on threat modeling and pull requests."
  },
  {
    "name": "Tech Lead",
    "description": "Guide technical implementation, oversee threat modeling, and resolve complex issues."
  },
  {
    "name": "Security Champion",
    "description": "Integrate security practices, conduct threat modeling, and validate compliance with security standards."
  },
  {
    "name": "Scrum Master",
    "description": "Facilitate daily stand-ups, track progress, and remove blockers during development."
  },
  {
    "name": "Test Engineer",
    "description": "Ensure the manual and automation suite preparation is started based on the defined scope and groomed user stories."
  }
  ]
      }
    },  
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Planning & Task Allocation",
            "description": {
              "Objective": [
                "Align development tasks with sprint goals and identify dependencies and security considerations early."
              ],
              "Activity": [
                "Review sprint backlog and ensure all user stories have defined acceptance criteria.",
                "Identify technical dependencies, security risks, and architectural concerns.",
                "Assign tasks to developers and ensure readiness for execution."
              ],
              "Outcome": [
                "A clear and actionable development plan aligned with sprint goals."
              ]
            }
          },
          {
            "title": "Coding & Threat Modeling",
            "description": {
              "Objective": [
                "Develop secure, functional, and high-quality code while proactively identifying and mitigating security risks."
              ],
              "Activity": [
                "Write code adhering to secure coding standards like OWASP and Microsoft's SDL.",
                "Conduct **threat modeling** early to identify and mitigate potential risks using tools like Microsoft Threat Modeling Tool.",
                "Utilize IDE security plugins (e.g., Visual Studio, VS Code) to identify vulnerabilities during coding."
              ],
              "Outcome": [
                "Secure, standards-compliant code ready for integration."
              ]
            }
          },
          {
            "title": "Code Reviews & Peer Collaboration",
            "description": {
              "Objective": [
                "Enhance code quality and security through collaborative peer reviews."
              ],
              "Activity": [
                "Submit code changes through pull requests (PRs) in Azure Repos or Git.",
                "Review code for adherence to secure coding standards, functional requirements, and readability.",
                "Address peer feedback and resolve identified issues before merging."
              ],
              "Outcome": [
                "Reviewed and approved code ready for integration."
              ]
            }
          },
          {
            "title": "CI & Static Code Analysis",
            "description": {
              "Objective": [
                "Validate the quality, security, and compliance of the code through automated analysis and integration."
              ],
              "Activity": [
                "Integrate code into the CI/CD pipeline using tools like Azure DevOps.",
                "Perform **static code analysis** using tools like SonarQube to identify vulnerabilities and coding issues.",
                "Check for open-source compliance and vulnerabilities using tools like Mend."
              ],
              "Outcome": [
                "Validated code integrated into the CI pipeline with security scans complete."
              ]
            }
          },
          {
            "title": "Post-Merge Validation & Pre-Staging Readiness",
            "description": {
              "Objective": [
                "Ensure the merged code is fully integrated, tested, and ready for the next stages."
              ],
              "Activity": [
                "Execute integration tests to validate cross-module functionality.",
                "Conduct performance and scalability testing, ensuring the application meets benchmarks.",
                "Prepare the validated code for staging and subsequent QA processes."
              ],
              "Outcome": [
                "Code validated and ready for staging or further testing."
              ]
            }
          }
        ]
      }
    },
    {type:'attachment',
      value:{
        title:'Media/Attachments (2)',
        items:[
          {src:'assets/media/images/adapt-devcadence.png', description:'Development alignment with teams'},
          {src:'assets/media/images/adapt-devsecops.png', description:'Development Process with focus on DevSecOps practices'}

        ]
      }
    }, 
    {
      type:'listItem',
      value:{
        title:'Artifacts and Deliverables',
        items:[
          {title:'Developed Code', description: 'Well-written, functional, and secure code committed to the repository.'
          },
          {title:'Threat Models', description: 'Diagrams and documents highlighting identified security risks and mitigation strategies.'
          },
          {title:'Static Code Analysis Reports', description: 'Reports from tools like SonarQube detailing code quality and compliance.'
          },
          {title:'Integration Test Reports', description: 'Automated test results validating cross-module interactions.'
          },
          {title:'Open Source Compliance', description: 'Reports from tools like Mend detailing open source license compliance, and vulnarability exposure.'
          }
        ]
      }
    }
  ];
}