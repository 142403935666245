import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-azure-devops',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class AzureDevopsComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Azure DevOps is a comprehensive set of development tools and services designed to support the entire application lifecycle. It enables seamless collaboration between development, testing, and operations teams, fostering agility and efficiency. With features like version control, CI/CD pipelines, work item tracking, and integrated testing frameworks, Azure DevOps helps teams deliver high-quality software faster and more efficiently.'
      }
    },
    {
      type: 'hyperlink',
      value: {
        heading: 'KDI Instances', // Section heading
        links: [
          {
            title: 'Kognifai Azure DevOps',
            url: 'https://kognifai.visualstudio.com/'
          },
          {
            title: 'SiteCom Azure DevOps',
            url: 'https://kdi-sitecom.visualstudio.com/'
          },
          {
            title: 'Maritime Simulation Azure DevOps',
            url: 'https://dev.azure.com/maritimesim/'
          }
        ]
      }
    }
     
  ];
}