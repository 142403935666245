import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashScreenModule } from './_metronic/partials';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MsalModule,
  MsalInterceptor,
  MsalBroadcastService,
  MsalService,
  MsalGuard,
  MsalRedirectComponent
} from "@azure/msal-angular";
import { InteractionType, PublicClientApplication, BrowserCacheLocation } from "@azure/msal-browser";
import { environment } from '../environments/environment';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { EthosPrincipalComponent } from './pages/static-pages/ethos-principal';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { CultureImpactComponent } from './pages/static-pages/cultural-impact';
import { SummaryMenuViewComponent } from './pages/summary-menu-view/summary-menu-view.component';
import { SunburstComponent } from './pages/sunburst/sunburst.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PageTemplateComponent } from './pages/page-template/page-template.component';
import { AdaptLifecycleComponent } from './pages/static-pages/Introduction & Overview/Overview/adapt-lifecycle.component';
import { ProductReadinessQaSignoffComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Development & Product Readiness/product-readiness-qa-signoff.component';
import { SprintPlanningComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Development & Product Readiness/sprint-planning.component';
import { StagingComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Development & Product Readiness/staging.component';
import { CompetitiveAnalysisComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Discovery & Validation/competitive-analysis.component';
import { ContinuousDiscoveryValidationComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Discovery & Validation/continuous-discovery-validation.component';
import { MarketCustomerAnalysisComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Discovery & Validation/market-customer-analysis.component';
import { MarketSegmentationComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Discovery & Validation/market-segmentation.component';
import { TechExternalAnalysisComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Discovery & Validation/tech-external-analysis.component';
import { TechnicalExplorationComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Discovery & Validation/technical-exploration.component';
import { ContentCreationBrandMessagingComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Go-to-Market & Scaling/content-creation-brand-messaging.component';
import { PartnerEngagementComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Go-to-Market & Scaling/partner-engagement.component';
import { PricingStrategyControlsComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Go-to-Market & Scaling/pricing-strategy-controls.component';
import { ProductLaunchPromotionComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Go-to-Market & Scaling/product-launch-promotion.component';
import { SalesEnablementComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Go-to-Market & Scaling/sales-enablement.component';
import { DeploymentPlanningSupportComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Release & Operational Readiness/deployment-planning-support.component';
import { OperationalReadinessSignoffComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Release & Operational Readiness/operational-readiness-signoff.component';
import { EstimationComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Roadmap & Planning/estimation.component';
import { ProductBacklogRefinementComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Roadmap & Planning/product-backlog-refinement.component';
import { RequirementsComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Roadmap & Planning/requirements.component';
import { StakeholderAlignmentComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Roadmap & Planning/stakeholder-alignment.component';
import { BusinessCaseComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Strategy & Vision/business-case.component';
import { GovernanceInvestmentComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Strategy & Vision/governance-investment.component';
import { ProductPortfolioStrategyComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Strategy & Vision/product-portfolio-strategy.component';
import { StrategicRoadmapComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Strategy & Vision/strategic-roadmap.component';
import { StrategyCascadeComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Strategy & Vision/strategy-cascade.component';
import { RoadmapComponent } from './pages/static-pages/Information & Support/Change Log & Updates/roadmap.component';
import { VersionHistoryComponent } from './pages/static-pages/Information & Support/Change Log & Updates/version-history.component';
import { AzureDevopsComponent } from './pages/static-pages/Information & Support/Tools & Services/azure-devops.component';
import { MendComponent } from './pages/static-pages/Information & Support/Tools & Services/mend.component';
import { ProductPlanComponent } from './pages/static-pages/Information & Support/Tools & Services/product-plan.component';
import { SonarqubeComponent } from './pages/static-pages/Information & Support/Tools & Services/sonarqube.component';
import { AdaptCertificationsComponent } from './pages/static-pages/Information & Support/Training & Resources/adapt-certifications.component';
import { FaqsComponent } from './pages/static-pages/Information & Support/Training & Resources/faqs.component';
import { OnboardingGuideComponent } from './pages/static-pages/Information & Support/Training & Resources/onboarding-guide.component';
import { TrainingModulesComponent } from './pages/static-pages/Information & Support/Training & Resources/training-modules.component';
import { DefinitionsImportanceComponent } from './pages/static-pages/Introduction & Overview/Metrics & KPIs/definitions-importance.component';
import { OverviewMetricsKpiComponent } from './pages/static-pages/Introduction & Overview/Metrics & KPIs/overview-metrics-kpi.component';
import { UsingInsightPortalComponent } from './pages/static-pages/Introduction & Overview/Metrics & KPIs/using-insight-portal.component';
import { IntroductionAdaptComponent } from './pages/static-pages/Introduction & Overview/Overview/introduction-adapt.component';
import { KeyPrinciplesComponent } from './pages/static-pages/Introduction & Overview/Overview/key-principles.component';
import { DevelopmentTeamComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/development-team.component';
import { ProductDesignerComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/product-designer.component';
import { ProductDirectorComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/product-director.component';
import { ProductManagerComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/product-manager.component';
import { ProductMarketingManagerComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/product-marketing-manager.component';
import { ProductOwnerComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/product-owner.component';
import { RadMatrixComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/rad-matrix.component';
import { ReleaseManagerComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/release-manager.component';
import { ScrumMasterComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/scrum-master.component';
import { TechLeadComponent } from './pages/static-pages/Introduction & Overview/Roles & Responsibilities/tech-lead.component';
import { SprintReviewRetroComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Development & Product Readiness/sprint-review-retro.component';
import { TestingComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Development & Product Readiness/testing.component';
import { DevelopmentComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Development & Product Readiness/development.component';
import { ReleaseManagementComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Release & Operational Readiness/release-management.component';
import { TrainingComponent } from './pages/static-pages/ADAPT Lifecycle Stages/Release & Operational Readiness/training.component';
import { ThreatModelingComponent } from './pages/static-pages/Information & Support/Best Practices/threat-modeling.component';
import { BestPracticesComponent } from './pages/static-pages/Information & Support/Best Practices/best-practices.component';
import { MarketModellingComponent } from './pages/static-pages/Information & Support/Techniques/market-modelling.component';
import { TechniquesComponent } from './pages/static-pages/Information & Support/Techniques/techniques.component';
import { RouterModule } from '@angular/router';
import { WorkEstimationComponent } from './pages/static-pages/Information & Support/Best Practices/work-estimation.component';

@NgModule({
  declarations: [AppComponent,HomePageComponent, SunburstComponent, SummaryMenuViewComponent, PageTemplateComponent , AdaptLifecycleComponent,
    ProductReadinessQaSignoffComponent,
    SprintPlanningComponent,
    StagingComponent,
    CompetitiveAnalysisComponent,
    ContinuousDiscoveryValidationComponent,
    MarketCustomerAnalysisComponent,
    MarketSegmentationComponent,
    TechExternalAnalysisComponent,
    TechnicalExplorationComponent,
    ContentCreationBrandMessagingComponent,
    PartnerEngagementComponent,
    PricingStrategyControlsComponent,
    ProductLaunchPromotionComponent,
    SalesEnablementComponent,
    DeploymentPlanningSupportComponent,
    OperationalReadinessSignoffComponent,
    TrainingComponent,
    EstimationComponent,
    ProductBacklogRefinementComponent,
    RequirementsComponent,
    StakeholderAlignmentComponent,
    BusinessCaseComponent,
    GovernanceInvestmentComponent,
    ProductPortfolioStrategyComponent,
    StrategicRoadmapComponent,
    StrategyCascadeComponent,
    RoadmapComponent,
    VersionHistoryComponent,
    AzureDevopsComponent,
    MendComponent,
    ProductPlanComponent,
    SonarqubeComponent,
    AdaptCertificationsComponent,
    FaqsComponent,
    OnboardingGuideComponent,
    TrainingModulesComponent,
    DefinitionsImportanceComponent,
    OverviewMetricsKpiComponent,
    UsingInsightPortalComponent,
    AdaptLifecycleComponent,
    IntroductionAdaptComponent,
    KeyPrinciplesComponent,
    DevelopmentTeamComponent,
    ProductDesignerComponent,
    ProductDirectorComponent,
    ProductManagerComponent,
    ProductMarketingManagerComponent,
    ProductOwnerComponent,
    RadMatrixComponent,
    ReleaseManagerComponent,
    ScrumMasterComponent,
    TechLeadComponent,
    SprintReviewRetroComponent,
    DevelopmentComponent,
    TestingComponent,
    ReleaseManagementComponent,
    ThreatModelingComponent,
    BestPracticesComponent,
    MarketModellingComponent,
    TechniquesComponent,
    WorkEstimationComponent
    

  ],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    SplashScreenModule,
    // SweetAlert2Module.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    //Retrieve Access token
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientId,
          authority: environment.authority,
          redirectUri: environment.redirectUri,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: false
        }
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [`${environment.clientId}/.default`],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          [environment.apiUri, [`${environment.clientId}/.default`]]
        ]),
      }
    ),
    NgbPopoverModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [MsalRedirectComponent, AppComponent],
})
export class AppModule { }
