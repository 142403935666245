import { Component } from '@angular/core';

@Component({
  selector: 'pet-scrum-master',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ScrumMasterComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Role Definition',
        subText:'A Scrum Master in KDI is responsible for promoting and supporting the team. Scrum Masters do this by helping everyone understand efficient value delivery and continuous improvement.'
      }
    },     
    {
      type: 'checkListItem',
      value: {
        title: 'Prerequisites',
        items: [
          { id: 1, text: 'When a person has multiple roles, scrum master is the first priority.' },
          { id: 2, text: 'Cannot be combined with product owner' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Responsibilities',
        items: [
          { id: 1, text: 'Follow up on the team contract.' },
          { id: 2, text: 'Facilitating Scrum events as requested or needed and keep such events timeboxed.' },
          { id: 3, text: 'Ensuring that goals and scope of the team are understood by the team.' },
          { id: 4, text: 'Attend the Backlog grooming sessions, SoS and Retrospectives in the Outer Loop.' },
          { id: 5, text: 'Reporting – Regular analysis of development team progress to understand what’s being built and at what cadence.' },
          { id: 6, text: 'Coaching and promoting ADAPT best practices within the team(s).' },
          { id: 7, text: 'Removing impediments to the team’s progress.' },
          { id: 8, text: 'Working with other Scrum Masters to increase the agility of the organization.' }
        ]
      }
    }, 
];
}
