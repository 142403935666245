import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-mend',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class MendComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Mend (formerly known as WhiteSource) is a software composition analysis (SCA) platform designed to manage open-source security and compliance. It helps development teams identify and remediate vulnerabilities in open-source dependencies, ensuring secure and compliant software delivery. With integration into CI/CD pipelines, real-time alerts, and actionable insights, Mend streamlines the process of maintaining open-source security while reducing risks.'
      }
    },
    {
      type: 'hyperlink',
      value: {
        heading: 'KDI Instances', // Section heading
        links: [
          {
            title: 'KDI Mend Instance',
            url: 'https://app-eu.whitesourcesoftware.com/Wss/WSS.html#!home'
          }
        ]
      }
    }
     
  ];
}