import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-market-customer-analysis',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class MarketCustomerAnalysisComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Market and customer analysis forms the foundation for building products that align with real customer needs and market demands. It involves understanding customer behaviors, trends, and challenges, as well as identifying opportunities for growth. This process integrates qualitative and quantitative research to guide strategic decision-making and product development.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product VP",
    "description": "Approves findings and outcomes to ensure they align with the strategic direction of the organization."
  },
  {
      "name": "Product Manager",
      "description": "Responsible for coordinating market and customer analysis activities and ensuring insights align with product strategy."
    },
    {
      "name": "Customer Success Team",
      "description": "Provides direct feedback from customer interactions to enrich analysis with real-world data."
    },
    {
      "name": "Growth Team",
      "description": "Contributes sales data and insights on market opportunities to guide analysis."
    },
    {
      "name": "Strategy Team (CSO)",
      "description": "Ensures the analysis aligns with broader corporate strategy and market trends."
    },
    {
      "name": "Product Design",
      "description": "Uses insights from analysis to support persona mapping and validate user-centered design principles."
    }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Customer Trends and Market Trends Analysis",
            "description": {
              "Objective": ["Identify customer pain points, behaviors, and emerging market trends."],
              "Activity": [
                "Organize and conduct customer advisory panels to collect direct feedback.",
                "Facilitate user group meetings to engage with end-users and capture detailed feedback.",
                "Analyze market data and internal reports to identify trends and challenges.",
                "Host internal advisory panels to leverage in-house expertise for strategic planning."
              ],
              "Outcome": ["A comprehensive understanding of customer needs and market trends, enabling data-driven product strategy decisions."]
            }
          },
          {
            "title": "Persona Development",
            "description": {
              "Objective": ["Create detailed customer personas to represent key user groups."],
              "Activity": [
                "Collect demographic, behavioral, and contextual data from customer interactions.",
                "Develop persona maps highlighting motivations, goals, and challenges.",
                "Refine personas through continuous feedback and alignment with customer data."
              ],
              "Outcome": ["Detailed personas that serve as reference points for product design and development."]
            }
          },
          {
            "title": "Business Case Development",
            "description": {
              "Objective": ["Develop a robust business case to prioritize opportunities."],
              "Activity": [
                "Use a standardized business case template to document risks, benefits, and ROI.",
                "Incorporate data from customer panels and market analysis to validate the case.",
                "Align business case insights with strategic goals and roadmap priorities."
              ],
              "Outcome": ["Validated business cases that justify resource allocation and strategic focus."]
            }
          }
        ]
      }
    },    
    {
      type: 'superListItem',
      value: {
        title: 'Practical Examples',
        items: [
          {
            title: 'Example 1', description:
            {
              'Objective': ['Using customer panels, a product team discovers a common pain point in usability for a specific feature. This insight informs a roadmap adjustment to prioritize UX improvements.'],
              'Activity': [],
              'Outcome': []
            }
          },
          {
            title: 'Example 2', description:
            {
              'Objective': ['Conducting market trend analysis identifies a growing demand for sustainability features in a SaaS product. This insight leads to the development of eco-friendly functionalities.'],
              'Activity': [],
              'Outcome': []
            }
          }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Tools / Resources / Templates',
        items: [
          { id: 1, text: 'Market research platforms (e.g., Qualtrics, SurveyMonkey).' },
          { id: 2, text: 'Collaboration tools (e.g., LucidSpark) for mapping personas.' },
          { id: 3, text: 'Business case template.' },
          { id: 4, text: 'Persona mapping framework.' }
        ]
      }
    },
    
  ];
}