import { Component } from '@angular/core';

@Component({
  selector: 'pet-definitions-importance',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class DefinitionsImportanceComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'Metrics and Key Performance Indicators (KPIs) are essential tools for tracking progress, identifying areas of improvement, and aligning teams with organizational goals. In the ADAPT framework, metrics serve as the foundation for data-driven decision-making and continuous improvement. They provide visibility into product development, process efficiency, and strategic alignment. This section explores the definition, benefits, and potential repercussions of each metric to emphasize their critical role in achieving measurable outcomes.'
      }
    },   
    {
      type: 'superListItem',
      value: {
        title: 'Overview of Metrics & KPIs',
        items: [
          {
            title: 'Developer Maturity Index',
            description: {
              'Definition': ['Tracks adherence to development best practices like CI/CD, code reviews, and coding standards.'],
              'Benefits': ['Ensures consistent development quality.', 'Reduces technical debt.', 'Encourages best practices.'],
              'Repercussions': ['Inconsistencies in development processes.', 'Increased bugs.', 'Delayed releases.']
            }
          },
          {
            title: 'Cycle Time and Lead Time',
            description: {
              'Definition': ['Cycle Time measures the time taken to complete a task, while Lead Time tracks the entire journey from ideation to delivery.'],
              'Benefits': ['Identifies bottlenecks.', 'Streamlines delivery workflows.', 'Improves delivery speed.'],
              'Repercussions': ['Prolonged delivery timelines.', 'Reduced team efficiency.', 'Customer dissatisfaction.']
            }
          },
          {
            title: 'Releasability',
            description: {
              'Definition': ['Evaluates whether a product is ready for deployment.'],
              'Benefits': ['Ensures smooth and timely releases.', 'Minimizes deployment risks.', 'Enhances customer trust.'],
              'Repercussions': ['Failed deployments.', 'Reduced customer satisfaction.', 'Increased post-release defects.']
            }
          },
          {
            title: 'Undefined User Story Index (UUSI)',
            description: {
              'Definition': ['Measures the proportion of user stories without clear acceptance criteria or definition.'],
              'Benefits': ['Improves development efficiency.', 'Reduces rework.', 'Enhances team alignment.'],
              'Repercussions': ['Misaligned expectations.', 'Delayed development.', 'Inefficiencies in execution.']
            }
          },
          {
            title: 'Azure Security Score',
            description: {
              'Definition': ['Tracks cloud security compliance and identifies vulnerabilities in the Azure environment.'],
              'Benefits': ['Enhances security posture.', 'Builds customer trust.', 'Minimizes potential risks.'],
              'Repercussions': ['Increased vulnerability to breaches.', 'Non-compliance with regulations.', 'Loss of trust.']
            }
          },
          {
            title: 'Last Sprint Status (LSS)',
            description: {
              'Definition': ['Summarizes the outcomes of the last sprint, highlighting completed and incomplete tasks.'],
              'Benefits': ['Improves accountability.', 'Enhances planning for future sprints.', 'Supports continuous improvement.'],
              'Repercussions': ['Backlogs of incomplete tasks.', 'Missed sprint goals.', 'Reduced stakeholder confidence.']
            }
          },
          {
            title: 'OS Vulnerability Management',
            description: {
              'Definition': ['Monitors and resolves vulnerabilities in open-source components used in the project.'],
              'Benefits': ['Mitigates security risks.', 'Ensures compliance with open-source standards.', 'Protects project integrity.'],
              'Repercussions': ['Potential security breaches.', 'Compliance violations.', 'Operational risks.']
            }
          },
          {
            title: 'Open-Source License Compliance',
            description: {
              'Definition': ['Ensures the organization adheres to open-source license terms and avoids conflicts.'],
              'Benefits': ['Facilitates smooth integration of third-party components.', 'Prevents legal disputes.', 'Maintains cost efficiency.'],
              'Repercussions': ['Legal disputes.', 'Unexpected licensing costs.', 'Project delays.']
            }
          }
        ]
      }
    },       
];
}
