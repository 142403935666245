import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-content-creation-brand-messaging',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ContentCreationBrandMessagingComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Content Creation and Brand Messaging ensure that the product’s value is effectively communicated to the market through engaging and consistent messaging. This process involves developing sales and marketing collateral, including website content, explainer videos, case studies, social media messaging, FAQs, white papers, and presentations. By aligning with the overall marketing strategy, this process supports digital marketing efforts, sales enablement, and customer engagement to establish a compelling brand presence.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product Manager",
    "description": "Approves messaging and ensures alignment with the overall product strategy."
  },
  {
    "name": "Product Marketing",
    "description": "Leads the creation of content, messaging, and collateral in collaboration with other teams."
  },
  {
    "name": "Customer Success",
    "description": "Provides insights into customer needs and feedback to guide messaging and content creation."
  },
  {
    "name": "Growth Team",
    "description": "Coordinates digital marketing efforts, campaigns, and content dissemination."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Defining Value Proposition",
            "description": {
              "Objective": [
                "Articulate the unique value the product delivers to its target customers."
              ],
              "Activity": [
                "Collaborate with stakeholders to identify the product’s key differentiators and benefits.",
                "Use frameworks like NOSE (Needs, Opportunities, Solutions, Evidence) to refine messaging.",
                "Develop a concise and compelling value proposition for use across all content."
              ],
              "Outcome": [
                "A clear value proposition that communicates the product’s relevance, differentiation, and value."
              ]
            }
          },
          {
            "title": "Creating Marketing Communication Plan",
            "description": {
              "Objective": [
                "Outline a structured plan for delivering consistent messaging across channels."
              ],
              "Activity": [
                "Develop a communication calendar for content dissemination.",
                "Define key themes and messaging aligned with the marketing strategy.",
                "Identify appropriate channels for engaging the target audience (e.g., social media, email, website)."
              ],
              "Outcome": [
                "A detailed marketing communication plan that aligns with overall business goals."
              ]
            }
          },
          {
            "title": "Developing Collateral and Sales Tools",
            "description": {
              "Objective": [
                "Create high-quality marketing and sales collateral to support campaigns and outreach efforts."
              ],
              "Activity": [
                "Write and design materials such as case studies, white papers, and brochures.",
                "Develop digital assets like explainer videos and infographics.",
                "Prepare sales tools like elevator pitches, FAB (Features, Advantages, Benefits) templates, and battlecards."
              ],
              "Outcome": [
                "A comprehensive set of sales and marketing materials tailored to customer needs and aligned with the brand."
              ]
            }
          },
          {
            "title": "Supporting Digital Marketing Initiatives",
            "description": {
              "Objective": [
                "Ensure content supports ongoing digital marketing efforts and amplifies brand visibility."
              ],
              "Activity": [
                "Create targeted social media posts and campaigns to drive engagement.",
                "Develop SEO-optimized website content to enhance search visibility.",
                "Write email marketing content to nurture leads and retain customers."
              ],
              "Outcome": [
                "Optimized digital marketing campaigns supported by engaging and impactful content."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Artifacts and Deliverables',
        items:[
          {title:'Value Proposition Document', description: 'A concise statement capturing the product’s key benefits and relevance to customers.'
          },
          {title:'Marketing Communication Plan', description: 'A calendar and strategy document for distributing content across channels.'
          },
          {title:'Sales Collateral', description: 'Brochures, case studies, FAQs, white papers, and presentations.'
          },
          {title:'Product Academy Content and Infographics', description: 'Visual aids that simplify complex concepts and highlight product value.'
          },
          {title:'Battlecards', description: 'Competitive comparison tools for sales teams to articulate differentiation.'
          }
        ]
      }
    }
  ];
}