import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-governance-investment',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class GovernanceInvestmentComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Governance and investment activities ensure that resources, budgets, and strategic priorities are consistently aligned with business goals. This process serves as the backbone for effective decision-making regarding product, technology, and Go-to-Market (GTM) investments. It allows teams to evaluate in-life product performance, make course corrections, and justify new investments with a business case.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Chief Product Officer (CPO)",
    "description": "Approves strategic investment plans and monitors alignment with organizational objectives."
  },
  {
    "name": "Chief Strategy Officer (CSO)",
    "description": "Drives the governance process and ensures resource allocation aligns with business strategy."
  },
  {
    "name": "Chief Technology Officer (CTO)",
    "description": "Provides input on technological feasibility and evaluates resource needs."
  },
  {
    "name": "Customer Success and Growth Teams",
    "description": "Offer insights on market dynamics and customer needs to influence investment decisions."
  },
  {
    "name": "Finance Team",
    "description": "Monitors budgets and ensures financial feasibility of proposed investments."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Strategic Alignment",
            "description": {
              "Objective": [
                "Align resource allocation and investment priorities with the strategic objectives of the business."
              ],
              "Activity": [
                "Identify investment themes based on strategic priorities.",
                "Engage cross-functional stakeholders to ensure alignment with business goals."
              ],
              "Outcome": [
                "A roadmap of investment priorities that reflects the business's strategic direction."
              ]
            }
          },
          {
            "title": "Portfolio Review",
            "description": {
              "Objective": [
                "Assess the performance of in-life products to identify areas requiring intervention."
              ],
              "Activity": [
                "Use performance dashboards and KPIs to evaluate in-life product performance.",
                "Propose course corrections or optimizations based on findings."
              ],
              "Outcome": [
                "A portfolio with improved performance and alignment with current market needs."
              ]
            }
          },
          {
            "title": "Investment Justification",
            "description": {
              "Objective": [
                "Provide a robust business case for proposed investments, new or accelerated."
              ],
              "Activity": [
                "Draft business cases detailing the expected ROI, strategic fit, and resource requirements.",
                "Present cases to governance boards for approval."
              ],
              "Outcome": [
                "Approved investments with clear justifications and expected outcomes."
              ]
            }
          },
          {
            "title": "Budget Management",
            "description": {
              "Objective": [
                "Ensure proactive management of Capex and Opex budgets across the portfolio."
              ],
              "Activity": [
                "Monitor budgets and expenditures against strategic targets.",
                "Allocate resources dynamically based on evolving priorities."
              ],
              "Outcome": [
                "Optimized budget utilization with minimal waste and maximum ROI."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Key Outcomes',
        items:[
          {title:'Resource allocation and prioritization aligned with strategic goals.', description: ''
          },
          {title:'Justification for planned or new investments based on business cases.', description: ''
          },
          {title:'Proactive budget management across Capex and Opex.', description: ''
          }
        ]
      }
    }
  ];
}