import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-strategy-cascade',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class StrategyCascadeComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'The Strategy Cascade process ensures that the corporate strategy is effectively communicated, cascaded, and aligned with current and planned product strategies. It interlocks goals, budgets, and investments across the product portfolio to integrate technology, operations, and go-to-market (GTM) efforts. This alignment ensures that strategic objectives drive coherent actions across all levels of the organization.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Chief Product Officer (CPO)",
    "description": "Approves the overarching strategy cascade and ensures alignment with product strategies."
  },
  {
    "name": "Chief Strategy Officer (CSO)",
    "description": "Responsible for driving the strategy cascade and ensuring interdepartmental collaboration."
  },
  {
    "name": "Chief Technology Officer (CTO)",
    "description": "Contributes to aligning technology strategies with corporate goals."
  },
  {
    "name": "Customer Success and Growth Teams",
    "description": "Provide insights and ensure operational alignment with strategic objectives."
  },
  {
    "name": "Finance Department",
    "description": "Ensures budgets are aligned with strategic priorities and monitors resource allocation."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Strategic Communication and Goal Setting",
            "description": {
              "Objective": [
                "Communicate corporate strategy to all relevant stakeholders and establish cascading goals."
              ],
              "Activity": [
                "Define SMART goals at corporate, divisional, and team levels.",
                "Align product strategies with overarching corporate goals using frameworks like Hoshin Methodology or Policy Deployment.",
                "Facilitate workshops to interlock technology, operations, and GTM strategies."
              ],
              "Outcome": [
                "A clear and aligned set of SMART goals communicated across the organization."
              ]
            }
          },
          {
            "title": "Portfolio Alignment and Investment Planning",
            "description": {
              "Objective": [
                "Ensure strategic alignment of product portfolio investments with corporate objectives."
              ],
              "Activity": [
                "Review and adjust budgets to align with strategic goals.",
                "Use the CSO Strategic Planning Process to evaluate and prioritize portfolio investments.",
                "Incorporate feedback from stakeholders in finance, technology, and operations."
              ],
              "Outcome": [
                "A balanced product portfolio aligned with corporate strategy and optimized for resource allocation."
              ]
            }
          },
          {
            "title": "Monitoring and Adjustments",
            "description": {
              "Objective": [
                "Track progress against strategic goals and adjust plans as necessary."
              ],
              "Activity": [
                "Monitor key performance indicators (KPIs) linked to strategic objectives.",
                "Conduct regular strategy review sessions to evaluate alignment and impact.",
                "Update and refine strategic plans based on market and organizational changes."
              ],
              "Outcome": [
                "Continuous alignment and refinement of product strategies with corporate objectives."
              ]
            }
          }
        ]
      }
    },    
    {
      type:'listItem',
      value:{
        title:'Artifacts and Deliverables',
        items:[
          {title:'Strategic Goal Documents', description: 'Documented SMART goals across corporate and product levels.'
          },
          {title:'Strategic Planning Reports', description: 'Output of CSO Strategic Planning Process.'
          },
          {title:'Portfolio Investment Plans', description: 'Updated budget allocations linked to strategic objectives.'
          },
          {title:'Alignment Dashboards', description: 'Visual tools to track goal progress and alignment across teams.'
          }
        ]
      }
    }
  ];
}