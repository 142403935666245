import { Component } from '@angular/core';
import { lang } from 'moment';

@Component({
  selector: 'pet-competitive-analysis',
  template:
    `
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class CompetitiveAnalysisComponent {
  public pageData: any = [

    {
      type: 'intro',
      value: {
        mainText: 'Introduction',
        subText: 'Market segmentation is the process of using discovery insights and other relevant data to identify attractive market segments. These segments can then be targeted with tailored propositions, driving product investment decisions. This process supports strategic alignment and ensures resources are directed toward high-potential opportunities.'
      }
    },
    {
      type: 'listItem',
      value: {
        title: 'Roles and Responsibilities',
"items": [
  {
    "name": "Product VP",
    "description": "Approves competitive analysis reports and ensures alignment with strategic objectives."
  },
  {
    "name": "Product Manager",
    "description": "Leads the competitive analysis process by gathering data, coordinating benchmarking activities, and deriving actionable insights."
  },
  {
    "name": "Customer Success",
    "description": "Provides customer-facing insights to validate competitive positioning and trends."
  },
  {
    "name": "Growth",
    "description": "Tracks market trends and aligns findings with marketing and growth strategies."
  },
  {
    "name": "Strategy (CSO)",
    "description": "Ensures alignment of competitive insights with overarching business strategies."
  }
  ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Benchmarking Competitive Trends",
            "description": {
              "Objective": [
                "Analyze competitors’ activities and performance to identify market trends and gaps."
              ],
              "Activity": [
                "Track competitor product launches, updates, and key business moves.",
                "Use benchmarking tools and techniques to compare performance metrics.",
                "Collaborate with Customer Success to understand competitors’ strengths and weaknesses from a customer perspective."
              ],
              "Outcome": [
                "A clear understanding of competitive trends and insights for strategic decisions."
              ]
            }
          },
          {
            "title": "Strategic Mapping and Positioning",
            "description": {
              "Objective": [
                "Evaluate competitive strengths, weaknesses, opportunities, and threats to refine positioning."
              ],
              "Activity": [
                "Use tools like strategy canvases and position maps to visualize competitive landscapes.",
                "Develop battlecards and Lumascapes to summarize competitor activities.",
                "Integrate win/loss analysis to identify reasons for successes or setbacks in the market."
              ],
              "Outcome": [
                "Actionable positioning strategies to differentiate from competitors."
              ]
            }
          }
        ]
      }
    },    
    {
      type: 'superListItem',
      value: {
        title: 'Practical Examples',
        items: [
          {
            title: 'Benchmarking', description:
            {
              'Objective': ['A SaaS product in the logistics domain compares its delivery tracking features against competitors to identify gaps and unique selling points.'],
              'Activity': [],
              'Outcome': []
            }
          },
          {
            title: 'Strategy Mapping', description:
            {
              'Objective': ['Using a position map, a team identifies its product’s niche strengths in "ease of use" compared to a competitor’s focus on "advanced analytics."'],
              'Activity': [],
              'Outcome': []
            }
          }
        ]
      }
    },
    
  ];
}