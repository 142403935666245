import { Component } from '@angular/core';

@Component({
  selector: 'pet-product-owner',
  template: 
  `
    <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class ProductOwnerComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Role Definition',
        subText:'A Product Owner in KDI follows the product backlog and decides the sprint backlog. It is an inward-facing role, in that they work closely with development teams to ensure the product is being built according to the requirements. The Product Owner is one person, not a committee. A product owner is a part of a "Agile/Scrum Team", which consists of Development Team, and Product Owner. The Agile/Scrum team collectively owns the Sprint Backlog.'
      }
    },     
    {
      type: 'checkListItem',
      value: {
        title: 'Prerequisites',
        items: [
          { id: 1, text: 'Authority for sprint backlog prioritization.' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Deliverables',
        items: [
          { id: 1, text: 'Prioritized and ordered Sprint Backlog.' },
          { id: 2, text: 'User Stories.' }
        ]
      }
    },
    {
      type: 'checkListItem',
      value: {
        title: 'Responsibilities',
        items: [
          { id: 1, text: 'Must talk regularly to relevant stakeholders like Product Managers, Development Teams, etc., to stay aligned and informed.' },
          { id: 2, text: 'Ensuring that the Sprint Backlog is transparent, visible, understood, and done according to the overall product roadmap (in collaboration with the Product Management Team).' },
          { id: 3, text: 'While the Product Owner oversees the creation of user stories, it\'s a team effort. Product Owner is responsible for ensuring a solid user stories suite, including clear Acceptance Criteria. Remember, the most knowledgeable people about a topic should contribute to the user story writing, fostering a collaborative approach.' },
          { id: 4, text: 'Prioritizing and Ordering the items in the Sprint Backlog to best achieve goals and values.' },
          { id: 5, text: 'Attend the grooming sessions, Alignment Gates, and Retrospective sessions in the Outer Loop as a representative for Product.' },
          { id: 6, text: 'Ensure the team composition is optimal for effective and efficient delivery.' }
        ]
      }
    }, 
];
}
