import { Component } from '@angular/core';

@Component({
  selector: 'pet-estimation',
  template: 
  `
  <p>
      Home > ADAPT Lifecycle Stages > Roadmap & Planning > Estimation
  </p>
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})


export class EstimationComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'Estimation in the Roadmap & Planning stage focuses on providing a high-level effort estimate for Features or Epics. This Rough Order of Magnitude (ROM) estimation helps inform strategic decisions, including Business Case justification, prioritization, and shaping the product roadmap. Unlike detailed sprint planning estimation, which occurs later in the Development & Product Readiness stage, this activity aims to assess technical complexity, skills required, and potential challenges at a broader level. The nature of this estimation is less granular and more strategic, focused on understanding the broader scope and complexity of the work rather than breaking it down into fine-grained tasks.'
      }
    },
    {
      type:'listItem',
      value:{
        title:'Roles and Responsibilities',
        items:[
          {name:'Product Manager', description:'Brings the business perspective, outlining the importance and potential impact of the feature.'},
          {name:'Product Manager/Owner*', description:'Facilitates the discussion, ensuring alignment with the overall product vision and objectives.'},
          {name:'Tech Lead', description:'Provides insights into the technical complexity and identifies dependencies or risks.'},
          {name:'Other Key Stakeholders', description:'Engages early in the planning process to contribute design considerations and ensure the requirements are user-centric. They help to validate concepts through prototypes and user feedback, contributing to a better-defined product vision.'}
        ]
      }
    },
    {
      type:'checkListItem',
      value:{
        title:'Prerequisites',
        items:[
          {id:1, text:'A well-defined Feature or Epic with a clear description and initial requirements.'},
          {id:2, text:'Alignment on strategic priorities and objectives for the upcoming planning cycle.'},
          {id:3, text:'Access to any relevant data or insights that could impact the estimate (e.g., previous similar features, technical feasibility studies).'}
        ]
      }
    },
    {
      type: 'superListItem',
      value: {
        title: 'Process Steps',
        items: [
          {
            title: 'Gather Initial Requirements',
            description: {
              'Objective': [
                'Understand the high-level requirements and objectives for the Feature or Epic.'
              ],
              'Activity': [
                'The Product Manager presents the initial requirements and outlines the strategic context.',
                'Ensure alignment with the product roadmap and strategic goals.'
              ],
              'Outcome': [
                'A clear set of high-level requirements to guide technical evaluation and estimation.'
              ]
            }
          },
          {
            title: 'Technical Assessment',
            description: {
              'Objective': [
                'Evaluate the technical feasibility, complexity, and dependencies of the proposed feature or epic.'
              ],
              'Activity': [
                'The Tech Lead or Architect assesses potential technical challenges and evaluates risks.',
                'Identify any architectural or design considerations that could impact implementation.'
              ],
              'Outcome': [
                'A technical assessment report detailing complexity, dependencies, and risks.'
              ]
            }
          },
          {
            title: 'Effort Estimation',
            description: {
              'Objective': [
                'Estimate the effort required for the feature or epic using proven estimation techniques.'
              ],
              'Activity': [
                'Apply techniques such as T-shirt sizing, Fibonacci sequence, or high-level point estimation.',
                'Discuss and calibrate estimates with the team to ensure a shared understanding.'
              ],
              'Outcome': [
                'A realistic, high-level effort estimate with documented assumptions and confidence levels.'
              ]
            }
          },
          {
            title: 'Risk & Dependency Analysis',
            description: {
              'Objective': [
                'Identify and assess risks and dependencies to mitigate potential blockers.'
              ],
              'Activity': [
                'Review external dependencies, technological uncertainties, and other risks.',
                'Document findings and assign ownership for resolving or monitoring these areas.'
              ],
              'Outcome': [
                'A risk and dependency matrix providing clarity on potential blockers and mitigation plans.'
              ]
            }
          },
          {
            title: 'Document & Review',
            description: {
              'Objective': [
                'Formalize the estimation process and ensure stakeholder alignment.'
              ],
              'Activity': [
                'Capture estimation details, including assumptions, identified risks, and confidence levels.',
                'Review the documented estimation with key stakeholders to finalize and approve.'
              ],
              'Outcome': [
                'An approved estimate with documented details ready for inclusion in planning activities.'
              ]
            }
          }
        ]
      }
    },
{type:'listItem',
  value:{
    title:'Practical Examples',
    items:[
      {name:'Example 1:', description:'Estimating a new API integration feature where the complexity of external dependencies needs to be considered.'},
      {name:'Example 2:', description:'Evaluating a user interface redesign, factoring in the design complexity and potential backend changes.'}
      
    ]
  }
},
{
  type:'checkListItem',
  value:{
    title:'Tools / Resources / Templates',
    items:[
      {id:1, text:'Templates for documenting ROM estimates, including sections for assumptions, risks, and dependencies.'},
      {id:2, text:'Azure DevOps to capture and manage estimates.'}
    ]
  }
},
];
}
