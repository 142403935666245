import { Component } from '@angular/core';

@Component({
  selector: 'pet-stakeholder-alignment',
  template: 
  `
  <p>
      Home > ADAPT Lifecycle Stages > Roadmap & Planning > Stakeholder Alignment
  </p>
  <pet-page-template [data]='pageData'></pet-page-template>
  `,
  styles: ``
})

export class StakeholderAlignmentComponent {
  public pageData:any = [

    {
      type:'intro',
      value:{
        mainText:'Introduction',
        subText:'Stakeholder Alignment is a critical process within the Roadmap & Planning stage of the ADAPT framework. It ensures that all key stakeholders are on the same page regarding the product vision, roadmap, and strategic priorities. This process facilitates collaboration, clarifies expectations, and aligns efforts across product management, engineering, and other relevant teams. It occurs at regular intervals to adjust for market changes, strategic shifts, and evolving product needs, creating a shared understanding that informs subsequent sprint planning and development activities.'
      }
    },
    {
      type:'listItem',
      value:{
        title:'Roles and Responsibilities',
        items:[
          {name:'Product Director', description:'Facilitates high-level discussions, sets strategic priorities, and ensures alignment with organizational goals.'},
          {name:'Product Manager', description:' Manages the roadmap and translates strategic priorities into actionable plans for the team.'},
          {name:'Product Manager/Owner*', description:'Works with stakeholders to clarify requirements, prioritize features, and ensure alignment with the roadmap.'},
          {name:'Tech Lead', description:'Provides input on technical feasibility, risks, and dependencies that impact roadmap decisions.'},
          {name:'Stakeholders (e.g., Customers, Business Leaders)', description:'Provide insights into business needs, market trends, and strategic imperatives.'},
          {name:'BPC & Product Forum)', description:'Ensure that ADAPT processes are adhered to and that learnings are shared across teams.'}
        ]
      }
    },
    {
      "type": "superListItem",
      "value": {
        "title": "Process Steps",
        "items": [
          {
            "title": "Quarterly Roadmap Alignment (Alignment Sync)",
            "description": {
              "Objective": [
                "Establish high-level goals for the next quarter and ensure alignment with ADAPT lifecycle stages."
              ],
              "Activity": [
                "Review and prioritize roadmap milestones, ensuring they reflect business value and strategic importance.",
                "Define quarterly goals and map them to the ADAPT lifecycle to maintain a strategic focus.",
                "Identify dependencies, risks, and any external factors that could impact delivery.",
                "Ensure that all stakeholder perspectives are incorporated to create a balanced and aligned roadmap."
              ],
              "Outcome": [
                "A clearly defined and prioritized quarterly roadmap segmented into high-priority milestones."
              ]
            }
          },
          {
            "title": "Sprint Planning with Roadmap Commitment",
            "description": {
              "Objective": [
                "Translate the high-level roadmap into actionable sprint goals and backlog items."
              ],
              "Activity": [
                "Review the quarterly roadmap and select items for the upcoming sprint.",
                "Ensure that sprint goals support roadmap milestones and align with strategic objectives.",
                "Prepare backlog items, prioritizing them based on impact, value, and feasibility."
              ],
              "Outcome": [
                "A committed sprint backlog that directly supports the strategic roadmap."
              ]
            }
          },
          {
            "title": "Continuous Backlog Refinement",
            "description": {
              "Objective": [
                "Keep the backlog relevant and aligned with evolving business needs."
              ],
              "Activity": [
                "Conduct regular refinement sessions to break down epics, adjust priorities, and refine acceptance criteria.",
                "Use feedback and new insights to reprioritize and prepare items for future sprints."
              ],
              "Outcome": [
                "A well-maintained backlog that aligns with roadmap goals, making it ready for sprint planning."
              ]
            }
          },
          {
            "title": "Risk Management and Mitigation",
            "description": {
              "Objective": [
                "Identify and address risks that may impact roadmap alignment and delivery."
              ],
              "Activity": [
                "Identify technical, resource, or market risks associated with roadmap items.",
                "Develop mitigation plans and ensure high-risk items are highlighted for stakeholder review."
              ],
              "Outcome": [
                "A proactive approach to risk management, reducing potential blockers and ensuring smooth delivery."
              ]
            }
          },
          {
            "title": "Feedback Loops",
            "description": {
              "Objective": [
                "Use continuous feedback to adjust and align the roadmap effectively."
              ],
              "Activity": [
                "Incorporate feedback from sprint reviews, stakeholders, and market data to refine roadmap priorities.",
                "Maintain open communication channels with stakeholders for ongoing alignment."
              ],
              "Outcome": [
                "A dynamic and responsive roadmap that adapts to new information and insights."
              ]
            }
          }
        ]
      }
    },
{type:'listItem',
  value:{
    title:'Practical Examples',
    items:[
      {name:'Quarterly Alignment:', description:'At the beginning of each quarter, the Product Manager organizes a workshop with key stakeholders to review the strategic roadmap. The Tech Lead highlights potential dependencies, while the Product Owner adjusts priorities based on input from business leaders.'},
      {name:'Risk Assessment:', description:'Before committing to a roadmap milestone, the team uses a risk matrix to identify technical and resource constraints, ensuring mitigation strategies are in place.'}
      
    ]
  }
},
{
  type:'checkListItem',
  value:{
    title:'Tools / Resources / Templates',
    items:[
      {id:1, text:'Roadmap Planning: A defined format on how the roadmap shall be planned and visualized on Product Plan, backed by Azure DevOps Epics.'},
      {id:2, text:'Risk Assessment Template: A downloadable template to categorize and address risks efficiently.'},
      {id:3, text:'Workshop Facilitation Guide: Tips for running effective roadmap alignment workshops.'}
    ]
  }
},
];
}
