import { Component } from '@angular/core';

@Component({
  selector: 'pet-product-director',
  template: `
    <div class="page-title-container">
      <div class="status-tag-container">
        <span [ngClass]="statusClass" class="status-tag">{{ status }}</span>
      </div>
    </div>
    <pet-page-template [data]="pageData"></pet-page-template>
  `,
  styles: [`
    .page-title-container {
      position: relative; /* Position the container relative for overlay */
    }
    .status-tag-container {
      position: absolute; /* Place the tag container relative to the title */
      top: -10px; /* Adjust the distance above the title */
      left: 0; /* Align with the title horizontally */
    }
    .status-tag {
      padding: 0.2rem 0.5rem;
      font-size: 0.85rem;
      font-weight: 500;
      border-radius: 12px;
      color: #fff;
    }
    .badge-draft {
      background-color: #007bff; /* Bootstrap primary blue */
    }
    .badge-published {
      background-color: #28a745; /* Bootstrap success green */
    }
  `]
})
export class ProductDirectorComponent {
  public status: string = 'Draft'; // Change to 'Published' as needed
  public statusClass: string = 'badge-draft'; // Dynamically set based on status

  public pageData: any = [
    {
      type: 'intro',
      value: {
        mainText: 'Role Definition',
        subText: 'A Product Director role in KDI is yet to be formalized. Please stay tuned for a formal role definition.'
      }
    }
  ];
}
